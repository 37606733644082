(function() {
    'use strict';

    angular
        .module('cscoe.editschool.modal', [])
        .controller('EditSchoolModalCtrl', EditSchoolModalCtrl);

    /* @ngInject */
    function EditSchoolModalCtrl($scope, FileReader, SchoolService, currentSchool, ModalService) {
        var vm = this;
        vm.title = 'EditSchoolModalCtrl';

        activate();

        function activate() {
            vm.school = currentSchool;
            vm.editSchool = editSchool;
            vm.closeModal = closeModal;
            vm.removeSchoolLogo = removeSchoolLogo;
            vm.readFile = readFile;
            vm.askToDeleteSchool = askToDeleteSchool;
            vm.deleteSchool = deleteSchool;

            vm.showSchoolInformation = true;
        }

        function readFile(file) {
            FileReader.readAsDataURL(file, $scope)
            .then(function (res) {
                vm.selectedImage = file;
                vm.school.schoolLogo = res;
            }),function (err) {
                console.log(err);
            };
        }

        function removeSchoolLogo(schoolUid) {
            SchoolService.removeSchoolLogo(schoolUid)
                .then(function () {
                    console.log('Logo removed');
                    vm.school.schoolLogo = undefined;
                    vm.selectedImage = null;
                }).catch(function(err) {
                    vm.school.schoolLogo = undefined;
                    vm.selectedImage = null;
                });
        }

        function editSchool(form) {
            if (form.$valid) {
                SchoolService.saveSchool(vm.school, vm.selectedImage)
                    .then(function (res) {
                        closeModal();
                    })
                    .catch(function (err) {
                        vm.error = err;
                    });
            }
        }

        function closeModal() {
            ModalService.close();
        }

        function askToDeleteSchool() {
            vm.showSchoolInformation = false;
        }

        function deleteSchool() {
            SchoolService.deleteSchool(currentSchool.uid)
                .then(function (res) {
                    console.log(res);
                    closeModal();
                });
        }
    }
})();
