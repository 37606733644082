(function() {
    'use strict';
    angular
        .module('cscoe.services.auth', [])
        .provider('AuthService', [function() {
            var nativeAuthObject = null;
            var firebaseConfig = firebaseConfig;

            this.configure = function(options) {
                nativeAuthObject = options.nativeAuthObject;
                firebaseConfig = {
                    connectionConfig: options.firebaseConfig,
                    firebaseInstance: options.firebase
                };
            };

            this.$get = ['$firebaseAuth', '$firebaseRef', '$firebaseArray', '$q', function($firebaseAuth, $firebaseRef, $firebaseArray, $q) {
                return new AuthService($firebaseAuth, $firebaseRef, $firebaseArray, $q, nativeAuthObject, firebaseConfig);
            }];

        }]);

    /* @ngInject */
    function AuthService($firebaseAuth, $firebaseRef, $firebaseArray, $q, nativeAuthObject, firebaseConfig) {

        var _identity = null;
        var _authorized = false;
        var auth = $firebaseAuth();
        var _authNative = nativeAuthObject;
        var firebaseConnectionsCounter = 1;

        var AuthSockets = [];

        return {
            login: login,
            logout: logout,
            createAcc: createAcc,
            removeAcc: removeAcc,
            verifyPasswordResetCode: verifyPasswordResetCode,
            confirmPasswordReset: confirmPasswordReset,
            createAuthObject: createAuthObject,
            getUserStatus: getUserStatus,
            getCurrentUser: getCurrentUser,
            setUserStatusToVerified: setUserStatusToVerified,
            checkActionCode: checkActionCode,
            applyActionCode: applyActionCode,
            sendPasswordResetEmail: sendPasswordResetEmail,
            isAdmin: isAdmin,
            onAuthStateChanged: auth.$onAuthStateChanged,
            getUserRoles: getUserRoles,
            waitForSignIn: waitForSignIn,
            killSockets: killSockets
        };

        function killSockets() {
            for (var i = 0; i < AuthSockets.length; i++) {
                if (typeof AuthSockets[i].on === 'function') {
                    AuthSockets[i].off();
                } else {
                    AuthSockets[i].$destroy();
                }
            }
        };

        function login(user) {
            var username = user.email;
            var password = user.password;
            var deferred = $q.defer();
            auth.$signInWithEmailAndPassword(username, password)
                .then(function(firebaseUser) {
                    return checkIfUserExists(firebaseUser);
                })
                .catch(function(error) {
                    deferred.reject(error);
                })
                .then(function(firebaseUser) {
                    return checkIfUserIsAdmin(firebaseUser.uid);
                })
                .catch(function(error) {
                    deferred.reject(error);
                })
                .then(function(isAdmin) {
                    deferred.resolve(isAdmin);
                })
                .catch(function(error) {
                    deferred.reject(error);
                });

            return deferred.promise;
        }

        function createAcc(user) {
            var deferred = $q.defer();
            var username = user.email;
            var password = user.password;

            auth.$createUserWithEmailAndPassword(username, password)
            .then(function(firebaseUser) {

                firebaseUser.sendEmailVerification();

                return AddUserToDatabase(firebaseUser);
            })
            .then(function(firebaseUser) {
                deferred.resolve(firebaseUser);
            }).catch(function(error) {
                deferred.reject(error);
            });

            return deferred.promise;
        }

        function createAuthObject() {
            return createAnotherAppInstance().auth();
        }

        function createAnotherAppInstance() {
            var app = firebaseConfig.firebaseInstance.initializeApp(firebaseConfig.connectionConfig, firebaseConnectionsCounter.toString());
            firebaseConnectionsCounter++;
            return app;
        }

        function removeAcc(userId) {
            var deferred = $q.defer();
            var usersRef = $firebaseRef.users;
            var usersCollection = $firebaseArray(usersRef);

            AuthSockets.push(usersCollection); // closes the sockets on logout

            usersCollection.$loaded()
                .then(function() {
                    var recordIndex = usersCollection.$indexFor(userId);
                    return usersCollection.$remove(recordIndex);
                })
                .then(function(removedRecord) {
                    deferred.resolve(removedRecord);
                });

            return deferred.promise;

            auth.$deleteUser().then(function() {
                console.log('User removed successfully!');
            }).catch(function(error) {
                console.error('Error: ', error);
            });
        }

        function checkIfUserIsAdmin(uid) {
            var deferred = $q.defer();
            var userRef = $firebaseRef.users
                .child(uid)
                .on('value', function(snap) {
                    deferred.resolve(snap.val().roles.indexOf('admin') !== -1);
                });

            return deferred.promise;
        }

        function isAdmin() {
            var user = getCurrentUser();
            if (user) {
                return checkIfUserIsAdmin(user.uid);
            } else {
                return $q.reject('No logged user.');
            }
        }

        function checkIfUserExists(user) {
            var deferred = $q.defer();
            //if it doesn't go add it to database
            var usersRef = $firebaseRef.users,
                userList = $firebaseArray(usersRef);

            AuthSockets.push(userList); // closes the sockets on logout

            userList.$loaded()
                .then(function(res) {
                    var userExists = false;
                    for (var i = 0; i < res.length; i++) {
                        userExists = res[i].$id === user.uid;
                        if (userExists) {
                            deferred.resolve(user);
                            break;
                        }
                    }

                    if (!userExists) {
                        AddUserToDatabase(user);
                    }
                });

            return deferred.promise;
        }

        function AddUserToDatabase(user) {
            var usersRef = $firebaseRef.users,
                userList = $firebaseArray(usersRef);

            AuthSockets.push(userList); // closes the sockets on logout

            var _user = {
                email: user.email,
                roles: ['user']
            };

            //We use .set here to avoid automatic generated uid in user collection
            usersRef.child(user.uid).set(_user);

            // return $q.resolve(user);
        }

        function getUserStatus(user) {
            var userRef = $firebaseRef.users.child(user.uid);
            userRef.on('value',function(snap) {
                console.log(snap);
            });
        }

        function getUserRoles() {
            var def = $q.defer();
            var user = getCurrentUser();
            var userRef = $firebaseRef.users.child(user.uid);
            userRef.on('value',function(snap) {
                def.resolve(snap.val().roles);
            });

            return def.promise;
        }

        function setUserStatusToVerified() {
            var def = $q.defer();
            auth.$waitForSignIn().then(function(firebaseUser) {
                if (firebaseUser) {
                    var userRef = $firebaseRef.users.child(firebaseUser.uid);
                    userRef.on('value', function(snap) {
                        var userToUpdate = snap.val();
                        if (!userToUpdate.verified && firebaseUser.emailVerified) {
                            userToUpdate.verified = true;
                            userRef.set(userToUpdate);
                        }

                        def.resolve(firebaseUser);
                    });
                }
            });

            return def.promise;
        }

        function waitForSignIn() {
            return auth.$waitForSignIn();
        }

        function verifyPasswordResetCode(actionCode) {
            if (_authNative) {
                return _authNative.verifyPasswordResetCode(actionCode);
            } else {
                return $q.reject(new Error('Native Firebase Auth is not configured. Try passing it to AuthServiceProvider.configure(options).'));
            }
        }

        function confirmPasswordReset(actionCode, newPassword) {
            if (_authNative) {
                return _authNative.confirmPasswordReset(actionCode, newPassword);
            } else {
                return $q.reject(new Error('Native Firebase Auth is not configured. Try passing it to AuthServiceProvider.configure(options).'));
            }
        }

        function checkActionCode(actionCode) {
            var def = $q.defer();

            if (_authNative) {
                _authNative.checkActionCode(actionCode)
                    .then(function (info) {
                        def.resolve(info);
                    })
                    .catch(function (err) {
                        def.reject(err);
                    });
            }

            return def.promise;
        }

        function applyActionCode(actionCode) {
            var def = $q.defer();
            if (_authNative) {
                _authNative.applyActionCode(actionCode).then(function(res) {
                    def.resolve(auth.$getAuth());
                });
            } else {
                return $q.reject(new Error('Native Firebase Auth is not configured. Try passing it to AuthServiceProvider.configure(options).'));
            }

            return def.promise;
        }

        function getCurrentUser() {
            return auth.$getAuth();
        }

        function sendPasswordResetEmail(email) {
            return _authNative.sendPasswordResetEmail(email);
        }

        function logout() {
            _identity = null;
            _authorized = false;
            return $firebaseAuth().$signOut();
        }

    }
})();
