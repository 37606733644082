(function() {
    'use strict';

    angular
        .module('cscoe.services.reports', [])
        .service('ReportsService', ReportsService);

    /* @ngInject */
    function ReportsService($q, $firebaseObject, $firebaseArray, $rootScope, $firebaseRef) {

        var ReportsDataSockets = [];

        return {
            getReportFBObject: getReportFBObject,
            createEmptyReportFBOBject: createEmptyReportFBOBject,
            getAllReports: getAllReports,
            getReports: getReports,
            saveReport: saveReport,
            updateReport: updateReport,
            deleteReport: deleteReport,
            saveTimeStamp: saveTimeStamp,
            killSockets: killSockets
        };

        function killSockets() {
            for (var i = 0; i < ReportsDataSockets.length; i++) {
                if (typeof ReportsDataSockets[i].on === 'function') {
                    ReportsDataSockets[i].off();
                } else {
                    ReportsDataSockets[i].$destroy();
                }
            }
        };


        function saveTimeStamp() {
            var def = $q.defer();

            var timestampRef = $firebaseRef.timestamp;
            var fbObject = $firebaseObject(timestampRef);

            fbObject.$loaded()
                .then(function (snap) {
                    def.resolve(snap.val);
                }).catch(function (err) {
                    // body...
                    console.log(err);
                    def.reject(err);
                });

            timestampRef.set({
                val: firebase.database.ServerValue.TIMESTAMP
            }).then(function () {
                ReportsDataSockets.push(fbObject);
            });

            return def.promise;
        }

        function getReportFBObject(reportId, schoolId) {
            var def = $q.defer();
            var reportsRef2 = $firebaseRef.schools;
            var reportRef2 = reportsRef2.child(schoolId).child('reports');
            var reportsObj2 = $firebaseArray(reportRef2);
            reportsObj2.$loaded()
            .then(function(arr){
                var hash = arr.filter(function(item){
                    return item.$value === reportId;
                });
                if(hash.length !== 0){
                    var reportsRef = $firebaseRef.reports;
                    var reportRef = reportsRef.child(reportId);
                    var reportsObj = $firebaseObject(reportRef);

                    ReportsDataSockets.push(reportsObj);  // used for closing sockets on logout

                    reportsObj.$loaded()
                    .then(function(data){
                        def.resolve(data);
                    })
                    .catch(function(err){
                        def.reject(err);
                    });
                } else {
                    def.reject('The requested report does not belog to this school');
                }
            })
            .catch(function(err){
                console.error(err);
                def.reject(err);
            })

            ReportsDataSockets.push(reportsObj2);  // used for closing sockets on logout

            return def.promise;
        }

        function createEmptyReportFBOBject(schoolId) {
            var def = $q.defer();
            var reportsRef = $firebaseRef.reports;
            var reportsCollection = $firebaseArray(reportsRef);

            ReportsDataSockets.push(reportsCollection); // used for closing sockets on logout

            reportsCollection.$add({schoolId:schoolId, year:firebase.database.ServerValue.TIMESTAMP}).then(function(ref) {
                var id = ref.key;
                reportsCollection.$loaded().then(function() {
                    var reportRef = reportsRef.child(id);
                    var reportObj = $firebaseObject(reportRef);

                    ReportsDataSockets.push(reportObj);  // used for closing sockets on logout

                    reportObj.$loaded()
                        .then(function(reportSnap) {
                            def.resolve(reportSnap);
                        });

                });
            });

            return def.promise;
        }

        function getAllReports(reportsIds, schoolUid) {
            var promises = [];
            for (var i = 0; i < reportsIds.length; i++) {
                promises.push(getReportFBObject(reportsIds[i], schoolUid));
            }

            return $q.all(promises);
        }

        function getReports() {
            var reportsRef = $firebaseRef.reports;
            var reportsList = $firebaseArray(reportsRef);
            return reportsList.$loaded();
        }

        function updateReport(report) {
            var newReportValue = {};
            for (var key in report) {
                if ((key.indexOf('$') === 0) ||
                    (typeof (report[key]) === 'function')) {
                    continue;
                }

                newReportValue[key] = report[key];
            }

            newReportValue.updated = report.updated = firebase.database.ServerValue.TIMESTAMP;

            var reportsRef = $firebaseRef.reports;
            var reportRef = reportsRef.child(report.$id);

            return reportRef.set(newReportValue);
        }

        function saveReport(report, schoolId) {
            var def = $q.defer();
            var reportsRef = $firebaseRef.reports;
            var reportsCollection = $firebaseArray(reportsRef);

            ReportsDataSockets.push(reportsCollection); // used for closing sockets on logout

            report.schoolId = schoolId;
            report.updated = firebase.database.ServerValue.TIMESTAMP;
            report.firstYear = (report.reportDates.startDate - 4).toString() + ' / ' + (report.reportDates.endDate - 4).toString();
            report.secondYear = (report.reportDates.startDate - 3).toString() + ' / ' + (report.reportDates.endDate - 3).toString();
            report.thirdYear = (report.reportDates.startDate - 2).toString() + ' / ' + (report.reportDates.endDate - 2).toString();
            report.fourthYear = (report.reportDates.startDate - 1).toString() + ' / ' + (report.reportDates.endDate - 1).toString();
            reportsCollection.$add(report).then(function(ref) {
                var id = ref.key;
                var index = reportsCollection.$indexFor(id);
                var savedReport = reportsCollection[index];
                def.resolve(savedReport);
            });

            return def.promise;
        }

        function deleteReport(reportId) {
            var reportsRef = $firebaseRef.reports;
            var reportToDelete = reportsRef.child(reportId);
            return reportToDelete.remove();
        }

    }
})();
