(function() {
    'use strict';

    angular
        .module('cscoe.verifyemail', [])
        .controller('VerifyEmailCtrl', VerifyEmailCtrl);

    /* @ngInject */
    function VerifyEmailCtrl($q, $firebase,$state, $stateParams, AuthService, $timeout) {
        var vm = this;
        vm.title = 'VerifyEmailCtrl';

        activate();

        ////////////////

        function activate() {
            vm.login = login;
            vm.logout = logout;
            vm.forgotPassword = forgotPassword;

            checkActionCode()
                .then(function (res) {
                    applyActionCode();
                })
                .catch(function (err) {
                    vm.error = err;

                    if (err.code === 'auth/invalid-action-code') {
                        vm.errorInvalid = true;
                        // vm.errorExpired = true;
                    } else if(err.code === 'auth/expired-action-code') {
                        vm.errorExpired = true;
                    }

                });

        }

        function resendVerificationLink() {
            var currentAuthObject = AuthService.getCurrentUser();
            currentAuthObject.sendEmailVerification()
                .then(function () {
                    $timeout(function() {
                        vm.hideForm = true;
                        vm.successMessage = true;
                        vm.loginErrShow = false;
                    }, 300);
                });
        }

        function forgotPassword() {
            if (vm.user && vm.user.email !== '' &&  vm.user.email !== undefined) {
                AuthService.sendPasswordResetEmail(vm.user.email)
                    .then(function() {
                        $state.go('noauthsplash');
                    }).catch(function (err) {
                        vm.loginErrMessage = err.message;
                        vm.loginErrShow = true;
                        $scope.$apply();
                    });
            } else {
                vm.loginErrMessage = 'Please enter first your email address and then click "Forgot password".';
                vm.loginErrShow = true;
            }
        }

        function logout() {
            AuthService.logout()
                .then(function () {
                    $state.go('login');
                });
        }

        function login(form) {
            if (form.$valid) {
                AuthService.login(vm.user)
                    .then(function() {
                        vm.loginErrShow = false;
                        vm.disableButton = true;
                        resendVerificationLink();
                    })
                    .catch(function(err) {
                        console.log(err);
                        vm.loginErrMessage = err;
                        vm.loginErrShow = true;
                    });
            }
        }

        function checkActionCode() {
            return AuthService.checkActionCode($state.params.oobCode);
        }

        function applyActionCode(argument) {

        AuthService.applyActionCode($state.params.oobCode)
                .then(function() {
                    $state.go('login');
                })
                .catch(function(error) {
                    // doesn't get to this portion, firebase throws an
                    // unhandled error if there's an error and doesn't propagate it
                    // through the callstack so that it could be handled
                    //
                    $state.go('splash', {
                        splashInformation: 'The action code is invalid. This can happen if the code is malformed, expired, or has already been used.',
                        splashHeader: 'Code is invalid or expired.'
                    });
                });
        }

    }
})();
