(function() {
    'use strict';

    angular
        .module('cscoe')
        .filter('numberConvertor', numberConvertor);

    function numberConvertor() {
        return filterFilter;

        function filterFilter(input) {
            return parseInt(input, 10);
        }
    }

})();
