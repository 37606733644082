//HEAD 
(function(app) {
try { app = angular.module("served.templates"); }
catch(err) { app = angular.module("served.templates", []); }
app.run(["$templateCache", function($templateCache) {
"use strict";

$templateCache.put("app/admin/admin.view.html","<div class=\"container-fluid\">\n" +
    "    <div class=\"container\">\n" +
    "        <div class=\"col-md-5 padding-15 center\">\n" +
    "            <img src=\"images/cscoe-logo.svg\" class=\"img-responsive center-block\">\n" +
    "        </div>\n" +
    "    </div>\n" +
    "</div>\n" +
    "\n" +
    "<div class=\"container-fluid background-dark-blue subheader\">\n" +
    "    <div class=\"container\">\n" +
    "        <div class=\"row\">\n" +
    "            <div class=\"col-md-8\"></div>\n" +
    "            <div class=\"col-xs-3 col-md-1\">\n" +
    "                <div class=\"row\">\n" +
    "                    <button type=\"button\" ng-csv=\"vm.getSchoolsCSV()\" csv-header=\"vm.getCSVHeader()\" csv-column-order=\"vm.getCSVOrder()\" class=\"btn btn-primary double-border btn-block dropdown-toggle\" filename=\"{{vm.getFilename()}}\">\n" +
    "                        <span class=\"glyphicon icon-download fs-24 lh-60\"></span>\n" +
    "                    </button>\n" +
    "                </div>\n" +
    "            </div>\n" +
    "            <div class=\"col-xs-3 col-md-1\">\n" +
    "                <div class=\"row\">\n" +
    "                    <button class=\"btn btn-primary border btn-block dropdown-toggle\" ng-click=\"vm.openModal('app/admin/create.account.modal.html', 'CreateSchoolModalCtrl')\">\n" +
    "                        <span class=\"glyphicon icon-plus fs-24 lh-60\"></span>\n" +
    "                    </button>\n" +
    "                </div>\n" +
    "            </div>\n" +
    "            <div class=\"col-xs-6 col-md-2\">\n" +
    "                <div class=\"btn-group width100perc\" uib-dropdown is-open=\"status.isopen\">\n" +
    "                    <div class=\"row\">\n" +
    "                        <button id=\"single-button\" type=\"button\" class=\"btn btn-primary border btn-block fs-16 lh-60\" uib-dropdown-toggle ng-disabled=\"disabled\">\n" +
    "                          Account <span class=\"margin-left-5 caret\"></span>\n" +
    "                        </button>\n" +
    "                    </div>\n" +
    "                    <ul class=\"dropdown-menu\" uib-dropdown-menu role=\"menu\" aria-labelledby=\"single-button\">\n" +
    "                        <li role=\"menuitem\"><a href=\"#\" ng-click=\"vm.logout($event)\">Log Out</a></li>\n" +
    "                    </ul>\n" +
    "                </div>\n" +
    "            </div>\n" +
    "        </div><!--ROW-->\n" +
    "    </div><!--CONTAINER-->\n" +
    "</div><!--CONTAINER-FLUID-->\n" +
    "\n" +
    "<div class=\"container-fluid padding-35\">\n" +
    "    <div class=\"container\">\n" +
    "        <div class=\"row\">\n" +
    "            <div class=\"col-lg-5\">\n" +
    "                <div class=\"row\">\n" +
    "                    <div class=\"col-lg-6\">\n" +
    "                        <div class=\"row\">\n" +
    "                            <input type=\"text\" class=\"form-control pos-relative search-field-admin\" placeholder=\"Search School\" ng-model=\"schoolSearch\">\n" +
    "                            <span class=\"glyphicon glyphicon-search pos-absolute search-icon\"></span>\n" +
    "                        </div>\n" +
    "                    </div>\n" +
    "                </div>\n" +
    "            </div>\n" +
    "        </div>\n" +
    "    </div>\n" +
    "</div>\n" +
    "\n" +
    "<div class=\"container admin-table\">\n" +
    "    <div class=\"row thead\">\n" +
    "        <div class=\"container padding-15\">\n" +
    "            <div class=\"col-lg-3 col\">\n" +
    "                <a href=\"\" ng-click=\"vm.setSortTypeName();vm.toggleReverse()\" class=\"font-bold\">\n" +
    "                    School Name\n" +
    "                    <span ng-show=\"vm.showCarretDown('name')\" class=\"glyphicon glyphicon-menu-down\"></span>\n" +
    "                    <span ng-show=\"vm.showCarretUp('name')\" class=\"glyphicon glyphicon-menu-up\"></span>\n" +
    "                </a>\n" +
    "            </div>\n" +
    "            <div class=\"col-lg-3 col\">\n" +
    "                <a href=\"\" ng-click=\"vm.setSortTypePrincipal();vm.toggleReverse()\" class=\"font-bold\">\n" +
    "                    Principal\n" +
    "                    <span ng-show=\"vm.showCarretDown('principalName')\" class=\"glyphicon glyphicon-menu-down\"></span>\n" +
    "                    <span ng-show=\"vm.showCarretUp('principalName')\" class=\"glyphicon glyphicon-menu-up\"></span>\n" +
    "                </a>\n" +
    "            </div>\n" +
    "            <div class=\"col-lg-4 col email\">\n" +
    "                <a href=\"\" ng-click=\"vm.setSortTypeEmail();vm.toggleReverse()\" class=\"font-bold\">\n" +
    "                    Account Email\n" +
    "                    <span ng-show=\"vm.showCarretDown('email')\" class=\"glyphicon glyphicon-menu-down\"></span>\n" +
    "                    <span ng-show=\"vm.showCarretUp('email')\" class=\"glyphicon glyphicon-menu-up\"></span>\n" +
    "                </a>\n" +
    "            </div>\n" +
    "            <div class=\"col-lg-2\">\n" +
    "                <div class=\"col-lg-6 preferences col\"></div>\n" +
    "                <div class=\"col-lg-6 preview col\"></div>\n" +
    "            </div>\n" +
    "        </div>\n" +
    "    </div>\n" +
    "    <div class=\"row\" ng-repeat=\"school in vm.schools | orderBy: vm.sortTable.sortType: vm.sortTable.sortReverse | filter:schoolSearch\">\n" +
    "        <div class=\"container padding-15\">\n" +
    "            <div class=\"col-lg-3 col word-break\">{{school.name}}</div>\n" +
    "            <div class=\"col-lg-3 col word-break\">{{school.principalName}}</div>\n" +
    "            <div class=\"col-lg-4 col email word-break\">{{school.email}}</div>\n" +
    "            <div class=\"col-lg-2\">\n" +
    "                <div class=\"col-lg-6 col-xs-6 preferences col\">\n" +
    "                    <div class=\"row border-none\">\n" +
    "                        <a class=\"pull-right reset-pull-right center-mobile cursor-pointer\" ng-click=\"vm.openModal('app/admin/edit.account.modal.html', 'EditSchoolModalCtrl' , school.$id)\">\n" +
    "                          <span class=\" glyphicon icon-settings blue-icons\"></span>\n" +
    "                        </a>\n" +
    "                    </div>\n" +
    "                </div>\n" +
    "                <div class=\"col-lg-6 col-xs-6 preview col\">\n" +
    "                    <div class=\"row border-none\">\n" +
    "                        <a class=\"pull-right reset-pull-right center-mobile cursor-pointer\" ui-sref=\"dashboard({schoolId: school.$id})\">\n" +
    "                          <span class=\"glyphicon icon-preview blue-icons\"></span>\n" +
    "                        </a>\n" +
    "                    </div>\n" +
    "                </div>\n" +
    "            </div>\n" +
    "        </div>\n" +
    "    </div>\n" +
    "</div>\n" +
    "")

$templateCache.put("app/admin/create.account.modal.html","<form ng-submit=\"vm.createSchool(createSchoolForm)\" name=\"createSchoolForm\" novalidate=\"true\">\n" +
    "    <div class=\"modal-body clearfix\" id=\"modal-body\">\n" +
    "        <div class=\"row\">\n" +
    "            <div class=\"col-lg-12\">\n" +
    "                <h2 class=\"text-center\">Create school</h2>\n" +
    "\n" +
    "                <div class=\"form-group col-lg-12\">\n" +
    "                    <label>School Name</label>\n" +
    "                    <input type=\"text\" name=\"schoolName\" class=\"form-control\" ng-model=\"vm.school.name\" placeholder=\"Enter School Name\" required>\n" +
    "                    <div ng-messages=\"createSchoolForm.schoolName.$error\" ng-if=\"createSchoolForm.schoolName.$dirty || createSchoolForm.$submitted\">\n" +
    "                        <div class=\"text-danger\" ng-message=\"required\">This field is required!</div>\n" +
    "                    </div>\n" +
    "                </div>\n" +
    "\n" +
    "                <div class=\"form-group col-lg-12\">\n" +
    "                    <label>Principal Name</label>\n" +
    "                    <input type=\"text\" class=\"form-control\" ng-model=\"vm.school.principalName\" placeholder=\"Enter Principal Name\">\n" +
    "                </div>\n" +
    "\n" +
    "                <div class=\"form-group col-lg-12\">\n" +
    "                    <label>Principal Email</label>\n" +
    "                    <input type=\"email\" name=\"principalEmail\" class=\"form-control\" ng-model=\"vm.school.email\" placeholder=\"email@email.edu\" required>\n" +
    "                    <div ng-messages=\"createSchoolForm.principalEmail.$error\" ng-if=\"createSchoolForm.principalEmail.$dirty || createSchoolForm.$submitted\">\n" +
    "                        <div class=\"text-danger\" ng-message=\"required\">This field is required!</div>\n" +
    "                        <div class=\"text-danger\" ng-message=\"email\">Your email address is invalid</div>\n" +
    "                    </div>\n" +
    "                </div>\n" +
    "\n" +
    "                <div class=\"form-group col-lg-12\">\n" +
    "                    <label>School Logo</label>\n" +
    "\n" +
    "                    <div ngf-drop ngf-select ngf-select-disabled=\"vm.disableSelect\" ng-model=\"files\" name=\"imageUpload\" class=\"drop-box col-lg-12\"\n" +
    "                        ngf-drag-over-class=\"'dragover'\" ngf-multiple=\"false\" ngf-allow-dir=\"true\"\n" +
    "                        accept=\"image/jpg,image/jpeg,image/png,image/gif\"\n" +
    "                        ngf-pattern=\".jpg,.jpeg,.png,.gif\"\n" +
    "                        ngf-validate=\"{pattern: '.jpg,.jpeg,.png,.gif'}\"\n" +
    "                        >\n" +
    "                        <div ng-if=\"!vm.selectedImage\">\n" +
    "                            Drop images here or click to upload<br>\n" +
    "                            (Accepts only jpg, png, gif)\n" +
    "                            <p class=\"margin-top30\">\n" +
    "                                <div class=\"btn btn-default\">UPLOAD</div>\n" +
    "                            </p>\n" +
    "                        </div>\n" +
    "                        <div class=\"col-lg-6 center pos-relative\">\n" +
    "                            <div class=\"pos-absolute clear-image glyphicon glyphicon-remove text-center background-dark-blue\" ng-click=\"vm.resetSelectedImage()\" ng-if=\"vm.selectedImage\"></div>\n" +
    "                            <img class=\"img-responsive center\" ngf-src=\"vm.selectedImage\" ng-if=\"vm.selectedImage\">\n" +
    "                        </div>\n" +
    "                    </div>\n" +
    "                    <div ng-messages=\"createSchoolForm.imageUpload.$error\">\n" +
    "                        <div class=\"text-danger\" ng-message=\"pattern\">The file format is not supported!</div>\n" +
    "                    </div>\n" +
    "                </div>\n" +
    "\n" +
    "                <div class=\"col-lg-12\">\n" +
    "                    <div class=\"alert alert-danger\" role=\"alert\" ng-if=\"vm.error\">\n" +
    "                        {{vm.error}}\n" +
    "                    </div>\n" +
    "                </div>\n" +
    "            </div>\n" +
    "        </div>\n" +
    "    </div>\n" +
    "    <div class=\"modal-footer\">\n" +
    "        <div class=\"row\">\n" +
    "            <div class=\"col-lg-12\">\n" +
    "                <div class=\"col-md-4\"></div>\n" +
    "                <div class=\"col-md-4 col-sm-6 col-xs-12 margin-top-10\">\n" +
    "                    <button class=\"btn btn-danger border-radius-none padding-20 btn-block\" type=\"button\" ng-click=\"vm.closeModal()\">Cancel</button>\n" +
    "                </div>\n" +
    "                <div class=\"col-md-4 col-sm-6 col-xs-12 margin-top-10\">\n" +
    "                    <button class=\"btn btn-primary border-radius-none padding-20 btn-block\" type=\"submit\">Create School</button>\n" +
    "                </div>\n" +
    "            </div>\n" +
    "        </div>\n" +
    "    </div>\n" +
    "</form>\n" +
    "")

$templateCache.put("app/admin/edit.account.modal.html","<form ng-submit=\"vm.editSchool(editSchoolForm)\" name=\"editSchoolForm\" novalidate=\"true\">\n" +
    "    <div class=\"modal-body clearfix\" id=\"modal-body\">\n" +
    "        <div class=\"row\" ng-if=\"vm.showSchoolInformation\">\n" +
    "            <div class=\"col-lg-12\">\n" +
    "                <h2 class=\"text-center\">Account Settings</h2>\n" +
    "\n" +
    "                <h4 class=\"col-lg-12 font-bold\">School info</h4>\n" +
    "                <div class=\"form-group col-lg-12\">\n" +
    "                    <label class=\"font-normal\">School Name</label>\n" +
    "                    <input type=\"text\" name=\"schoolName\" class=\"form-control\" ng-model=\"vm.school.name\" placeholder=\"Enter School Name\" required>\n" +
    "                        <div ng-messages=\"editSchoolForm.schoolName.$error\" ng-if=\"editSchoolForm.schoolName.$dirty || editSchoolForm.$submitted\">\n" +
    "                        <div class=\"text-danger\" ng-message=\"required\">This field is required!</div>\n" +
    "                    </div>\n" +
    "                </div>\n" +
    "\n" +
    "                <div class=\"form-group col-lg-12\">\n" +
    "                    <label class=\"font-normal\">Principal Name</label>\n" +
    "                    <input type=\"text\" class=\"form-control\" ng-model=\"vm.school.principalName\" placeholder=\"Enter Principal Name\">\n" +
    "                </div>\n" +
    "\n" +
    "                <div class=\"form-group col-lg-12\">\n" +
    "                    <label class=\"font-normal\">Principal Email</label>\n" +
    "                    <input type=\"email\" name=\"principalEmail\" class=\"form-control\" ng-model=\"vm.school.email\" placeholder=\"email@email.edu\" required>\n" +
    "                    <div ng-messages=\"editSchoolForm.principalEmail.$error\" ng-if=\"editSchoolForm.principalEmail.$dirty || editSchoolForm.$submitted\">\n" +
    "                        <div class=\"text-danger\" ng-message=\"required\">This field is required!</div>\n" +
    "                        <div class=\"text-danger\" ng-message=\"email\">Your email address is invalid</div>\n" +
    "                    </div>\n" +
    "                </div>\n" +
    "\n" +
    "                <div class=\"form-group col-lg-12\">\n" +
    "                    <div class=\"col-lg-6\">\n" +
    "                        <div class=\"row\">\n" +
    "                            <label class=\"show\">School Logo</label>\n" +
    "                        </div>\n" +
    "                        <div class=\"row margin-top-20 margin-bottom-20 pos-relative\">\n" +
    "                            <div class=\"pos-absolute clear-image glyphicon glyphicon-remove text-center background-dark-blue\" ng-click=\"vm.removeSchoolLogo(vm.school.uid)\" ng-if=\"vm.school.schoolLogo\"></div>\n" +
    "                            <img ngf-src=\"vm.school.schoolLogo\" class=\"img-responsive\">\n" +
    "                        </div>\n" +
    "                        <div class=\"row\">\n" +
    "                            <div class=\"btn border-radius-none padding-20 {{vm.school.schoolLogo ? 'btn-warning' : 'btn-success'}}\"\n" +
    "                                 name=\"imageUpload\"\n" +
    "                                 ngf-select\n" +
    "                                 ng-model=\"vm.file\"\n" +
    "                                 ngf-multiple=\"false\"\n" +
    "                                 ngf-change=\"vm.readFile(vm.file)\"\n" +
    "                                 accept=\"image/jpg,image/jpeg,image/png,image/gif\"\n" +
    "                                 ngf-pattern=\".jpg,.jpeg,.png,.gif\"\n" +
    "                                 ngf-validate=\"{pattern: '.jpg,.jpeg,.png,.gif'}\"\n" +
    "                                 >{{vm.school.schoolLogo ? 'REPLACE IMAGE' : 'ADD IMAGE'}}\n" +
    "                            </div>\n" +
    "                            <p> (Accepts only jpg, png, gif)</p>\n" +
    "                            <div ng-messages=\"editSchoolForm.imageUpload.$error\">\n" +
    "                                <div class=\"text-danger\" ng-message=\"pattern\">The file format is not supported!</div>\n" +
    "                            </div>\n" +
    "                        </div>\n" +
    "                    </div>\n" +
    "                </div>\n" +
    "\n" +
    "                <div class=\"col-lg-12\">\n" +
    "                    <div class=\"alert alert-danger\" role=\"alert\" ng-if=\"vm.error\">\n" +
    "                        {{vm.error}}\n" +
    "                    </div>\n" +
    "                </div>\n" +
    "            </div>\n" +
    "        </div>\n" +
    "\n" +
    "\n" +
    "        <div class=\"row\" ng-if=\"!vm.showSchoolInformation\">\n" +
    "           <div class=\"col-lg-12 center text-center\">\n" +
    "                <h3>Delete this School?</h3>\n" +
    "                <p class=\"margin-top-bottom-40\">Are you sure you want to delete this school? This action cannot be undone.</p>\n" +
    "                <div class=\"col-lg-12 center\">\n" +
    "                    <div class=\"row margin-bottom-20\">\n" +
    "                        <div class=\"col-lg-6\">\n" +
    "                            <button class=\"pull-right btn btn-danger btn-block border-radius-none border-none padding-20\" ng-click=\"vm.deleteSchool()\">DELETE SCHOOL</button>\n" +
    "                        </div>\n" +
    "                        <div class=\"col-lg-6\">\n" +
    "                            <button class=\"pull-left btn btn-primary btn-block border-radius-none border-none padding-20\" ng-click=\"vm.closeModal()\">CANCEL</button>\n" +
    "                        </div>\n" +
    "                    </div>\n" +
    "                </div>\n" +
    "           </div>\n" +
    "        </div>\n" +
    "\n" +
    "    </div>\n" +
    "    <div class=\"modal-footer padding-bottom-reset\" ng-if=\"vm.showSchoolInformation\">\n" +
    "        <div class=\"row\">\n" +
    "            <div class=\"col-sm-6 col-xs-12 margin-top15\">\n" +
    "                <button type=\"button\" class=\"btn btn-default btn-mobile-block\" data-dismiss=\"modal\" ng-click=\"vm.closeModal()\">CANCEL</button>\n" +
    "            </div>\n" +
    "            <div class=\"col-sm-6 col-xs-12 margin-top15\">\n" +
    "                <button type=\"submit\" class=\"btn btn-primary padding-20 pull-left btn-mobile-block\" data-dismiss=\"modal\">SAVE CHANGES</button>\n" +
    "            </div>\n" +
    "        </div>\n" +
    "        <div class=\"row\">\n" +
    "            <div class=\"col-lg-12\">\n" +
    "                <div class=\"row margin-top-30\">\n" +
    "                    <button type=\"button\" class=\"btn btn-danger btn-block padding-20 border-none border-radius-none\" ng-click=\"vm.askToDeleteSchool()\">DELETE SCHOOL</button>\n" +
    "                </div>\n" +
    "            </div>\n" +
    "        </div>\n" +
    "    </div>\n" +
    "</form>\n" +
    "")

$templateCache.put("app/auth/auth.view.html","")

$templateCache.put("app/dashboard/dashboard.view.html","<div class=\"container-fluid\">\n" +
    "    <div class=\"container flex-vertical-center dashboard-logo-container\">\n" +
    "        <div class=\"col-md-6 dashboard-header-logo\">\n" +
    "            <!-- <img src=\"images/cscoe-logo.svg\" class=\"img-responsive\"> -->\n" +
    "            <div class=\"image-background cscoe-logo\" ng-cloak></div>\n" +
    "        </div>\n" +
    "        <div class=\"col-md-6 col-xs-5 dashboard-school-logo\">\n" +
    "            <!-- <img ng-src=\"{{vm.currentSchool.schoolLogo}}\" class=\"img-responsive logo\" ng-cloak> -->\n" +
    "            <div class=\"image-background\" style=\"background-image: url('{{vm.currentSchool.schoolLogo}}');\" ng-cloak></div>\n" +
    "        </div>\n" +
    "    </div>\n" +
    "</div>\n" +
    "<div class=\"container-fluid background-dark-blue border-bottom-4\">\n" +
    "    <div class=\"container subheader\">\n" +
    "        <div class=\"row\">\n" +
    "            <div class=\"col-md-10\"></div>\n" +
    "            <div class=\"col-md-2\">\n" +
    "                <div class=\"btn-group width100perc\" uib-dropdown is-open=\"status.isopen\">\n" +
    "                    <div class=\"row\">\n" +
    "                        <button id=\"single-button\" type=\"button\" class=\"btn btn-primary double-border btn-block fs-16 lh-60\" uib-dropdown-toggle ng-disabled=\"disabled\">\n" +
    "                          Account <span class=\"margin-left-5 caret\"></span>\n" +
    "                        </button>\n" +
    "                    </div>\n" +
    "                    <ul class=\"dropdown-menu\" uib-dropdown-menu role=\"menu\" aria-labelledby=\"single-button\">\n" +
    "                        <li role=\"menuitem\" ng-if=\"vm.showResetPass\"><a ng-click=\"vm.sendPasswordResetEmail($event)\" ng-if=\"!vm.isAdmin\">Reset Password</a></li>\n" +
    "                        <li role=\"menuitem\"><a href=\"#\" ng-click=\"vm.logout($event)\">Log Out</a></li>\n" +
    "                    </ul>\n" +
    "                </div>\n" +
    "            </div>\n" +
    "        </div><!--ROW-->\n" +
    "    </div><!--CONTAINER-->\n" +
    "</div><!--CONTAINER-FLUID-->\n" +
    "\n" +
    "<div class=\"dashboard-background\">\n" +
    "    <div class=\"container text-center\">\n" +
    "        <h1 class=\"school-name\">{{vm.currentSchool.name}}</h1>\n" +
    "    </div>\n" +
    "\n" +
    "    <!--Header ends -->\n" +
    "\n" +
    "    <div class=\"container-fluid\">\n" +
    "        <div class=\"container padding-15\">\n" +
    "            <div class=\"row\">\n" +
    "               <div class=\"col-md-2\">\n" +
    "                    <div class=\"row\">\n" +
    "                        <label class=\"blue-text fs-16 font-bold\">Now viewing</label>\n" +
    "                       <!--  <select class=\"form-control\" ng-model=\"format\" ng-options=\"f for f in formats\"><option></option></select> -->\n" +
    "                        <div class=\"btn-group width100perc\" uib-dropdown is-open=\"status.yearSelectIsOpen\">\n" +
    "                          <button id=\"single-button\" type=\"button\" class=\"btn btn-primary btn-block padding-20 fs-16\" uib-dropdown-toggle>\n" +
    "                            {{vm.report.reportDates.startDate + ' / ' + vm.report.reportDates.endDate }}<span class=\"margin-left-5 caret\"></span>\n" +
    "                          </button>\n" +
    "                          <ul class=\"dropdown-menu dashboard padding-bottom-reset\" uib-dropdown-menu role=\"menu\" aria-labelledby=\"single-button\">\n" +
    "                            <li role=\"menuitem\" ng-repeat=\"report in vm.firebaseReports\">\n" +
    "                                <a href=\"#\" ng-click=\"$event.preventDefault(); vm.viewReport($index)\">{{report.timespan}}</a>\n" +
    "                            </li>\n" +
    "                          </ul>\n" +
    "                        </div>\n" +
    "                    </div>\n" +
    "                </div>\n" +
    "                <div class=\"col-md-7\">\n" +
    "                    <p class=\"faq-text update margin-top-30 fs-18\" ng-if=\"vm.report.updated\">Last updated : {{vm.report.updated | date : 'yyyy-MM-dd HH:mm:ss'}}</p>\n" +
    "                </div>\n" +
    "                <div class=\"col-md-3\">\n" +
    "                    <div class=\"row\">\n" +
    "                        <button class=\"btn btn-primary btn-block padding-20 fs-16 margin-top-30\" ng-click=\"vm.runReport(vm.report.$id)\">Run Snapshot Report</button>\n" +
    "                    </div>\n" +
    "                </div>\n" +
    "            </div><!--ROW-->\n" +
    "        </div><!--CONTAINER-->\n" +
    "    </div><!--CONTAINER-FLUID-->\n" +
    "    <div class=\"container\">\n" +
    "        <div class=\"row\">\n" +
    "        <uib-accordion close-others=\"oneAtATime\">\n" +
    "            <div uib-accordion-group class=\"dashboard-section\" is-open=\"status.academics\" is-disabled=\"status.isFirstDisabled\">\n" +
    "                <uib-accordion-heading>\n" +
    "                    <span class=\"dashboard-heading show width100perc\"><span>Academics <i class=\"icon-settings\" ng-click=\"vm.openSettings($event)\"></i></span>  <i class=\"pull-right icon-question fs-20\"></i></span></span>\n" +
    "                </uib-accordion-heading>\n" +
    "                <div class=\"faq-text\">\n" +
    "Please indicate, in the entry cells, your school proficiency test results in both reading and mathematics and compare them to state results. A “+” status symbol indicates school performance is superior to state, a “o” symbol indicates similar performance and a “-“ symbol indicates inferior performance. If you want to compare your school results to a different benchmark, you can click on the gear symbol in the header above to change the default 'State' label to the one of your choosing. There is no Goal entry in this section because we recommend that all schools have the same goal of consistently outperforming public schools.\n" +
    "                </div>\n" +
    "            </div>\n" +
    "\n" +
    "            <div class=\"container\">\n" +
    "                <div class=\"row dashboard-body\">\n" +
    "\n" +
    "                    <div class=\"col-md-8\">\n" +
    "                      <div class=\"row\">\n" +
    "                        <h4 class=\"font-bold\">Reading Proficiency</h4>\n" +
    "                        <div class=\"form-group col-md-5\">\n" +
    "                            <div class=\"row\">\n" +
    "                              <label class=\"font-normal\">My school</label>\n" +
    "                              <input type=\"text\" class=\"form-control\" placeholder=\"%\" ng-model=\"vm.report.academics.reading.myschool\" ng-change=\"vm.updateReport()\">\n" +
    "                            </div>\n" +
    "                        </div>\n" +
    "                        <div class=\"col-md-1\">\n" +
    "                            <div class=\"row\"></div>\n" +
    "                        </div>\n" +
    "                        <div class=\"form-group col-md-5\">\n" +
    "                            <div class=\"row\">\n" +
    "                              <label class=\"font-normal\">{{vm.report.comparison}}</label>\n" +
    "                              <input type=\"text\" class=\"form-control\" placeholder=\"%\" ng-model=\"vm.report.academics.reading.comparison\" ng-change=\"vm.updateReport()\">\n" +
    "                            </div>\n" +
    "                        </div>\n" +
    "                      </div><!--ROW-->\n" +
    "\n" +
    "                      <div class=\"row\">\n" +
    "                        <h4 class=\"font-bold\">Math Proficiency</h4>\n" +
    "                        <div class=\"form-group col-md-5\">\n" +
    "                            <div class=\"row\">\n" +
    "                              <label class=\"font-normal\">My school</label>\n" +
    "                              <input type=\"text\" class=\"form-control\" placeholder=\"%\" ng-model=\"vm.report.academics.mathematics.myschool\" ng-change=\"vm.updateReport()\">\n" +
    "                            </div>\n" +
    "                        </div>\n" +
    "                        <div class=\"col-md-1\"><div class=\"row\"></div></div>\n" +
    "                        <div class=\"form-group col-md-5\">\n" +
    "                            <div class=\"row\">\n" +
    "                              <label class=\"font-normal\">{{vm.report.comparison}}</label>\n" +
    "                              <input type=\"text\" class=\"form-control\" placeholder=\"%\" ng-model=\"vm.report.academics.mathematics.comparison\" ng-change=\"vm.updateReport()\">\n" +
    "                            </div>\n" +
    "                        </div>\n" +
    "                      </div><!--ROW-->\n" +
    "                    </div><!--col-md-4-->\n" +
    "\n" +
    "                    <div class=\"col-md-2\"></div>\n" +
    "\n" +
    "                    <div class=\"col-md-2\">\n" +
    "                        <div class=\"row\">\n" +
    "                            <h4 class=\"font-bold\">Status</h4>\n" +
    "                            <label class=\"btn btn-block btn-mobile-status btn-lime margin-bottom-20 btn-status\" ng-model=\"vm.report.academics.status\" uib-btn-radio=\"'plus'\" ng-click=\"vm.updateReport()\" uib-uncheckable=\"uncheckable\"><span class=\"icon-plus\"></span></label>\n" +
    "                            <label class=\"btn btn-block btn-mobile-status btn-orange margin-bottom-20 btn-status\" ng-model=\"vm.report.academics.status\" uib-btn-radio=\"'neutral'\" ng-click=\"vm.updateReport()\" uib-uncheckable=\"uncheckable\"><span class=\"icon-neutral\"></span></label>\n" +
    "                            <label class=\"btn btn-block btn-mobile-status btn-red margin-bottom-20 btn-status\" ng-model=\"vm.report.academics.status\" uib-btn-radio=\"'minus'\" ng-click=\"vm.updateReport()\" uib-uncheckable=\"uncheckable\"><span class=\"icon-minus\"></span></label>\n" +
    "                        </div>\n" +
    "                    </div>\n" +
    "                </div><!--ROW-->\n" +
    "            </div><!--CONTAINER-->\n" +
    "            <!--END OF ACADEMICS-->\n" +
    "\n" +
    "            <div uib-accordion-group class=\"dashboard-section\" is-open=\"status.formation\">\n" +
    "                <uib-accordion-heading>\n" +
    "                    <span class=\"dashboard-heading show width100perc\">Formation  <i class=\"pull-right icon-question fs-20\"></i></span>\n" +
    "\n" +
    "                </uib-accordion-heading>\n" +
    "                <div class=\"faq-text\">\n" +
    "This entry addresses Catholic Identity. Please enter your top Catholic identity goal for the year in the first (top) cell. The data cell below Goal indicates average number of days per week a student experiences her/his faith. Examples of activities that “count” towards this number are daily mass attendance, Eucharistic adoration, virtue formation classes, community service, daily prayer, vocations recruitment, parish Mass participation, etc. A “+” status symbol indicates kids experience their faith every day for at least one hour, a “o” symbol indicates 3 days a week for at least one hour and a “-“ symbol indicates less than 3 days a week. An example of a Formation goal might be adding adoration hours, daily Mass attendance, virtue formation, etc.\n" +
    "                </div>\n" +
    "            </div>\n" +
    "            <div class=\"container\">\n" +
    "                <div class=\"row dashboard-body\">\n" +
    "                    <div class=\"col-md-10\">\n" +
    "                        <div class=\"row\">\n" +
    "                            <h4 class=\"font-bold\">Goal</h4>\n" +
    "                            <div class=\"form-group col-md-10 clearfix\">\n" +
    "                                <div class=\"row\">\n" +
    "                                    <label class=\"font-normal\">My goal</label>\n" +
    "                                    <textarea class=\"form-control\" placeholder=\"Write your goal here...\" ng-model=\"vm.report.formation.goal\" ng-change=\"vm.updateReport()\"></textarea>\n" +
    "                                </div>\n" +
    "                            </div>\n" +
    "                        </div>\n" +
    "                    </div>\n" +
    "\n" +
    "                    <div class=\"col-md-2\">\n" +
    "                        <div class=\"row\">\n" +
    "                            <h4 class=\"font-bold\">Status</h4>\n" +
    "                            <label class=\"btn btn-block btn-mobile-status btn-lime margin-bottom-20 btn-status\" ng-model=\"vm.report.formation.status\" uib-btn-radio=\"'plus'\" ng-click=\"vm.updateReport()\" uib-uncheckable=\"uncheckable\"><span class=\"icon-plus\"></span></label>\n" +
    "                            <label class=\"btn btn-block btn-mobile-status btn-orange margin-bottom-20 btn-status\" ng-model=\"vm.report.formation.status\" uib-btn-radio=\"'neutral'\" ng-click=\"vm.updateReport()\" uib-uncheckable=\"uncheckable\"><span class=\"icon-neutral\"></span></label>\n" +
    "                            <label class=\"btn btn-block btn-mobile-status btn-red margin-bottom-20 btn-status\" ng-model=\"vm.report.formation.status\" uib-btn-radio=\"'minus'\" ng-click=\"vm.updateReport()\" uib-uncheckable=\"uncheckable\"><span class=\"icon-minus\"></span></label>\n" +
    "                        </div>\n" +
    "                    </div>\n" +
    "\n" +
    "                    <div class=\"col-md-12\">\n" +
    "                         <div class=\"row\">\n" +
    "                            <h4 class=\"font-bold\">Formation hours</h4>\n" +
    "                            <div class=\"form-group col-md-3\">\n" +
    "                                <div class=\"row\">\n" +
    "                                    <label class=\"font-normal\">Days / Week</label>\n" +
    "                                    <input type=\"text\" class=\"form-control\" placeholder=\"Days\" ng-model=\"vm.report.formation.hours\" ng-change=\"vm.updateReport()\">\n" +
    "                                </div>\n" +
    "                            </div>\n" +
    "                        </div>\n" +
    "                    </div>\n" +
    "\n" +
    "                </div><!--ROW-->\n" +
    "            </div><!--CONTAINER-->\n" +
    "            <!--END OF FORMATION-->\n" +
    "\n" +
    "            <div uib-accordion-group class=\"dashboard-section\" is-open=\"status.enrollment\">\n" +
    "                <uib-accordion-heading>\n" +
    "                    <span class=\"dashboard-heading show width100perc\">Enrollment<i class=\"pull-right icon-question fs-20\"></i></span>\n" +
    "                </uib-accordion-heading>\n" +
    "                <div class=\"faq-text\">\n" +
    "Please enter your top enrollment goal for the year in the first (top) cell. First data cell below the Goal cell indicates % growth in pre-K enrollment from the previous year. Please enter your pre-K enrollment for the past year and for the current year. The program calculates growth automatically. If your enrollment went down your growth will be negative. This is a key statistic to support any sustainability claim we might want to make. The following set of 5 data entry cells indicate how the number of total students (all grades including preK that your school offers) has changed at the school over the past 5 years. Please enter your total school’s enrollment for each one of the indicated 5 past years. A “+” status symbol indicates school is growing, a “o” symbol indicates enrollment is stagnant and a “-“ symbol indicates school is shrinking.\n" +
    "                </div>\n" +
    "            </div>\n" +
    "            <div class=\"container\">\n" +
    "                <div class=\"row dashboard-body\">\n" +
    "                    <div class=\"col-md-10\">\n" +
    "                        <div class=\"row\">\n" +
    "                            <h4 class=\"font-bold\">Goal</h4>\n" +
    "                            <div class=\"form-group col-md-10\">\n" +
    "                                <div class=\"row\">\n" +
    "                                    <label class=\"font-normal\">My goal</label>\n" +
    "                                    <textarea class=\"form-control\" placeholder=\"Write your goal here...\" ng-model=\"vm.report.enrollment.goal\" ng-change=\"vm.updateReport()\"></textarea>\n" +
    "                                </div>\n" +
    "                            </div>\n" +
    "                        </div>\n" +
    "                    </div>\n" +
    "                    <div class=\"col-md-2\">\n" +
    "                        <div class=\"row\">\n" +
    "                            <h4 class=\"font-bold\">Status</h4>\n" +
    "                            <label class=\"btn btn-block btn-mobile-status btn-lime margin-bottom-20 btn-status\" ng-model=\"vm.report.enrollment.status\" uib-btn-radio=\"'plus'\" ng-click=\"vm.updateReport()\" uib-uncheckable=\"uncheckable\"><span class=\"icon-plus\"></span></label>\n" +
    "                            <label class=\"btn btn-block btn-mobile-status btn-orange margin-bottom-20 btn-status\" ng-model=\"vm.report.enrollment.status\" uib-btn-radio=\"'neutral'\" ng-click=\"vm.updateReport()\" uib-uncheckable=\"uncheckable\"><span class=\"icon-neutral\"></span></label>\n" +
    "                            <label class=\"btn btn-block btn-mobile-status btn-red margin-bottom-20 btn-status\" ng-model=\"vm.report.enrollment.status\" uib-btn-radio=\"'minus'\" ng-click=\"vm.updateReport()\" uib-uncheckable=\"uncheckable\"><span class=\"icon-minus\"></span></label>\n" +
    "                        </div>\n" +
    "                    </div>\n" +
    "                    <div class=\"col-md-12\">\n" +
    "                        <div class=\"row\">\n" +
    "                            <h4 class=\"font-bold\">Pre-K Growth If Applicable</h4>\n" +
    "                            <div class=\"form-group col-md-7\">\n" +
    "                                <div class=\"row\">\n" +
    "                                    <div class=\"col-lg-6 margin-left-row-reset\">\n" +
    "                                        <label class=\"font-normal\">{{(vm.report.reportDates.startDate - 1) + ' / ' + (vm.report.reportDates.endDate - 1)}}</label>\n" +
    "                                        <input type=\"text\" class=\"form-control\" placeholder=\"Total Pre-K Enrollment\" ng-change=\"vm.totalEnrollment(vm.report.enrollment.lastYearEnrollment, vm.report.enrollment.currentYearEnrollment)\" ng-model=\"vm.report.enrollment.lastYearEnrollment\">\n" +
    "                                    </div>\n" +
    "                                    <div class=\"col-lg-6 margin-left-mobile\">\n" +
    "                                        <label class=\"font-normal\">{{vm.report.reportDates.startDate + ' / ' + vm.report.reportDates.endDate }}</label>\n" +
    "                                        <input type=\"text\" class=\"form-control\" placeholder=\"Total Pre-K Enrollment\" ng-change=\"vm.totalEnrollment(vm.report.enrollment.lastYearEnrollment, vm.report.enrollment.currentYearEnrollment)\" ng-model=\"vm.report.enrollment.currentYearEnrollment\">\n" +
    "                                    </div>\n" +
    "                                </div>\n" +
    "                            </div>\n" +
    "                            <div class=\"form-group col-md-2\">\n" +
    "                                <label class=\"font-normal\">% Change</label>\n" +
    "                                <div class=\"row\">\n" +
    "                                    <span class=\"font-bold col-lg-12\">= {{vm.report.totalEnrollment ? Math.round(vm.report.totalEnrollment) + '%' : 0 + '%'}}</span>\n" +
    "                                </div>\n" +
    "                            </div>\n" +
    "                        </div>\n" +
    "                        <!--ROW-->\n" +
    "                        <div class=\"row\">\n" +
    "                            <div class=\"col-lg-10\">\n" +
    "                                <div class=\"row\">\n" +
    "                                    <h4 class=\"font-bold\">Total School 5-Year Trend</h4>\n" +
    "                                    <div class=\"form-group col-md-2 margin-left-row-reset\">\n" +
    "                                        <label class=\"font-normal\">{{vm.report.firstYear}}</label>\n" +
    "                                        <input type=\"text\" class=\"form-control\" placeholder=\"Enrollment\" ng-model=\"vm.report.enrollmentFirstYearValue\" ng-change=\"vm.updateReport()\">\n" +
    "                                    </div>\n" +
    "                                    <div class=\"form-group col-md-2 margin-left-mobile\">\n" +
    "                                        <label class=\"font-normal\">{{vm.report.secondYear}}</label>\n" +
    "                                        <input type=\"text\" class=\"form-control\" placeholder=\"Enrollment\" ng-model=\"vm.report.enrollmentSecondYearValue\" ng-change=\"vm.updateReport()\">\n" +
    "                                    </div>\n" +
    "                                    <div class=\"form-group col-md-2 margin-left-mobile\">\n" +
    "                                        <label class=\"font-normal\">{{vm.report.thirdYear}}</label>\n" +
    "                                        <input type=\"text\" class=\"form-control\" placeholder=\"Enrollment\" ng-model=\"vm.report.enrollmentThirdYearValue\" ng-change=\"vm.updateReport()\">\n" +
    "                                    </div>\n" +
    "                                    <div class=\"form-group col-md-2 margin-left-mobile\">\n" +
    "                                        <label class=\"font-normal\">{{vm.report.fourthYear}}</label>\n" +
    "                                        <input type=\"text\" class=\"form-control\" placeholder=\"Enrollment\" ng-model=\"vm.report.enrollmentFourthYearValue\" ng-change=\"vm.updateReport()\">\n" +
    "                                    </div>\n" +
    "                                    <div class=\"form-group col-md-2 margin-left-mobile\">\n" +
    "                                        <label class=\"font-normal\">{{vm.report.reportDates.startDate + ' / ' + vm.report.reportDates.endDate }}</label>\n" +
    "                                        <input type=\"text\" class=\"form-control\" placeholder=\"Enrollment\" ng-model=\"vm.report.enrollmentFifthYearValue\" ng-change=\"vm.updateReport()\">\n" +
    "                                    </div>\n" +
    "                                </div>\n" +
    "                            </div>\n" +
    "                        </div>\n" +
    "                        <!--ROW-->\n" +
    "                    </div>\n" +
    "                    <!--col-md-4-->\n" +
    "                </div>\n" +
    "                <!--ROW-->\n" +
    "            </div>\n" +
    "            <!--CONTAINER-->\n" +
    "\n" +
    "\n" +
    "            <div uib-accordion-group class=\"dashboard-section\" is-open=\"status.satisfaction\">\n" +
    "                <uib-accordion-heading>\n" +
    "                    <span class=\"dashboard-heading show width100perc\">Satisfaction <i class=\"pull-right icon-question fs-20\"></i></span>\n" +
    "                </uib-accordion-heading>\n" +
    "                <div class=\"faq-text\">\n" +
    "Please enter your top satisfaction goal for the year in the first (top) cell. Data entry cell below the Goal cell indicates retention rate, i.e. % of students returning from the previous year-Of course seniors are excluded, or children that had to move out of the state, etc. are excluded too. An example of a goal for satisfaction might be to conduct a parent survey to measure satisfaction. CSCOE will help your school here. Additional goals can address gaps identified as a result of the survey. A “+” status symbol indicates school has an overall satisfaction rating > 90%, a “o” symbol indicates overall satisfaction rating ~90% and a “-“ symbol indicates overall satisfaction rating < 90%.\n" +
    "                </div>\n" +
    "            </div>\n" +
    "            <div class=\"container\">\n" +
    "                <div class=\"row dashboard-body\">\n" +
    "                    <div class=\"col-md-10\">\n" +
    "                        <div class=\"row\">\n" +
    "                            <h4 class=\"font-bold\">Goal</h4>\n" +
    "                            <div class=\"form-group col-md-10\">\n" +
    "                                <div class=\"row\">\n" +
    "                                    <label class=\"font-normal\">My goal</label>\n" +
    "                                    <textarea class=\"form-control\" placeholder=\"Write your goal here...\" ng-model=\"vm.report.satisfaction.goal\" ng-change=\"vm.updateReport()\"></textarea>\n" +
    "                                </div>\n" +
    "                            </div>\n" +
    "                        </div>\n" +
    "                    </div>\n" +
    "                    <div class=\"col-md-2\">\n" +
    "                        <div class=\"row\">\n" +
    "                            <h4 class=\"font-bold\">Status</h4>\n" +
    "                            <label class=\"btn btn-block btn-mobile-status btn-lime margin-bottom-20 btn-status\" ng-model=\"vm.report.satisfaction.status\" uib-btn-radio=\"'plus'\" ng-click=\"vm.updateReport()\" uib-uncheckable=\"uncheckable\"><span class=\"icon-plus\"></span></label>\n" +
    "                            <label class=\"btn btn-block btn-mobile-status btn-orange margin-bottom-20 btn-status\" ng-model=\"vm.report.satisfaction.status\" uib-btn-radio=\"'neutral'\" ng-click=\"vm.updateReport()\" uib-uncheckable=\"uncheckable\"><span class=\"icon-neutral\"></span></label>\n" +
    "                            <label class=\"btn btn-block btn-mobile-status btn-red margin-bottom-20 btn-status\" ng-model=\"vm.report.satisfaction.status\" uib-btn-radio=\"'minus'\" ng-click=\"vm.updateReport()\" uib-uncheckable=\"uncheckable\"><span class=\"icon-minus\"></span></label>\n" +
    "                        </div>\n" +
    "                    </div>\n" +
    "                    <div class=\"col-md-9\">\n" +
    "                        <div class=\"row\">\n" +
    "                            <h4 class=\"font-bold\">Retention Rate</h4>\n" +
    "                            <div class=\"form-group col-md-3\">\n" +
    "                                <div class=\"row\">\n" +
    "                                    <label class=\"font-normal\">{{vm.report.reportDates.startDate + ' / ' + vm.report.reportDates.endDate }}</label>\n" +
    "                                    <input type=\"text\" class=\"form-control\" placeholder=\"K-4 Enrollment\" ng-model=\"vm.report.satisfaction.retention\" ng-change=\"vm.updateReport()\">\n" +
    "                                </div>\n" +
    "                            </div>\n" +
    "                        </div>\n" +
    "                        <!--ROW-->\n" +
    "                    </div>\n" +
    "                    <!--col-md-4-->\n" +
    "                </div>\n" +
    "                <!--ROW-->\n" +
    "            </div>\n" +
    "            <!--CONTAINER-->\n" +
    "\n" +
    "\n" +
    "            <div uib-accordion-group class=\"dashboard-section\" is-open=\"status.financials\">\n" +
    "                <uib-accordion-heading>\n" +
    "                    <span class=\"dashboard-heading show width100perc\">Financials <i class=\"pull-right icon-question fs-20\"></i></span>\n" +
    "                </uib-accordion-heading>\n" +
    "                <div class=\"faq-text\">\n" +
    "The three Income fields capture the main sources of funding for the school. The Expenses field is self-explanatory and the Open Seats field should be checked if your school has availability at any grade level. If checked a table appears that can be used for tracking the number of open seats for each grade and NA can be used if a grade level is not offered. There is no Goal entry because we recommend that all schools have the same goal of consistently being in the black. A “+” status symbol indicates school on a surplus, a “o” symbol indicates a cash flow neutral situation and a “-“ symbol indicates the school operates on a deficit.\n" +
    "                </div>\n" +
    "            </div>\n" +
    "            <div class=\"container\">\n" +
    "                <div class=\"row dashboard-body\">\n" +
    "                    <div class=\"form-group col-md-10\">\n" +
    "\n" +
    "                        <div class=\"row margin-bottom-20\">\n" +
    "                            <h4 class=\"font-bold\">Income</h4>\n" +
    "                            <div class=\"row\">\n" +
    "                                <div class=\"col-md-3\">\n" +
    "                                    <label class=\"font-normal\">Parish Support</label>\n" +
    "                                    <input type=\"text\" class=\"form-control\" placeholder=\"$\" ng-model=\"vm.report.financials.income.parishsupport\" ng-change=\"vm.updateReport()\" ui-money-mask=\"0\">\n" +
    "                                </div>\n" +
    "                                <div class=\"col-md-3\">\n" +
    "                                    <label class=\"font-normal\">Fundraising</label>\n" +
    "                                    <input type=\"text\" class=\"form-control\" placeholder=\"$\" ng-model=\"vm.report.financials.income.fundraising\" ng-change=\"vm.updateReport()\" ui-money-mask=\"0\">\n" +
    "                                </div>\n" +
    "                                <div class=\"col-md-3\">\n" +
    "                                    <label class=\"font-normal\">Tuition</label>\n" +
    "                                    <input type=\"text\" class=\"form-control\" placeholder=\"$\" ng-model=\"vm.report.financials.income.tuition\" ng-change=\"vm.updateReport()\" ui-money-mask=\"0\">\n" +
    "                                </div>\n" +
    "                            </div>\n" +
    "                        </div>\n" +
    "\n" +
    "                        <div class=\"row\">\n" +
    "                            <div class=\"row\">\n" +
    "                                <div class=\"col-md-3\">\n" +
    "                                    <h4 class=\"font-bold\">Expenses</h4>\n" +
    "                                    <label class=\"font-normal\">Cost Per Student</label>\n" +
    "                                    <input type=\"text\" class=\"form-control\" placeholder=\"$\" ng-model=\"vm.report.financials.costPerStudent\" ng-change=\"vm.updateReport()\" ui-money-mask=\"0\"> \n" +
    "                                </div>\n" +
    "                                <div class=\"col-md-4\">\n" +
    "                                    <h4 class=\"font-bold\">Open Seats</h4>\n" +
    "                                    <label class=\"font-normal\">Check here if you have Open Seats</label>\n" +
    "                                    <!--<input type=\"text\" class=\"form-control\" ng-model=\"vm.report.financials.hasOpenSeats\" ng-change=\"vm.updateReport()\"> -->\n" +
    "                                    <button type=\"button\" class=\"checkbox-btn\" ng-model=\"vm.report.financials.hasOpenSeats\" uib-btn-checkbox btn-checkbox-true=\"true\" btn-checkbox-false=\"false\" ng-click=\"vm.updateReport()\">\n" +
    "                                        <i class=\"green-icon-checkbox glyphicon glyphicon-ok\" ng-if=\"vm.report.financials.hasOpenSeats\"></i>\n" +
    "                                    </button>\n" +
    "                                </div>\n" +
    "                            </div>\n" +
    "                        </div>\n" +
    "                    </div>\n" +
    "                    <div class=\"col-md-2\">\n" +
    "                        <div class=\"row\">\n" +
    "                            <h4 class=\"font-bold\">Status</h4>\n" +
    "                            <label class=\"btn btn-block btn-mobile-status btn-lime margin-bottom-20 btn-status\" ng-model=\"vm.report.financials.status\" uib-btn-radio=\"'plus'\" ng-click=\"vm.updateReport()\" uib-uncheckable=\"uncheckable\"><span class=\"icon-plus\"></span></label>\n" +
    "                            <label class=\"btn btn-block btn-mobile-status btn-orange margin-bottom-20 btn-status\" ng-model=\"vm.report.financials.status\" uib-btn-radio=\"'neutral'\" ng-click=\"vm.updateReport()\" uib-uncheckable=\"uncheckable\"><span class=\"icon-neutral\"></span></label>\n" +
    "                            <label class=\"btn btn-block btn-mobile-status btn-red margin-bottom-20 btn-status\" ng-model=\"vm.report.financials.status\" uib-btn-radio=\"'minus'\" ng-click=\"vm.updateReport()\" uib-uncheckable=\"uncheckable\"><span class=\"icon-minus\"></span></label>\n" +
    "                        </div>\n" +
    "                    </div>\n" +
    "                    <div ng-if=\"vm.report.financials.hasOpenSeats\">\n" +
    "                        <h4 class=\"font-bold\">Open Seats by Grade</h4>\n" +
    "                        <div class=\"col-md-12 col-lg-12 seats-table\">\n" +
    "                            <div class=\"row open-seats seats-row\">\n" +
    "                                <div class=\"hide-headings seats-container col-md-1 col-lg-1 font-bold\">Grade</div>\n" +
    "                                <div class=\"hide-headings seats-container col-md-1 col-lg-1 font-bold\">Pre-K</div>\n" +
    "                                <div class=\"hide-headings seats-container col-md-1 col-lg-1 font-bold\">K</div>\n" +
    "                                <div class=\"hide-headings seats-container col-md-1 col-lg-1 font-bold\">1</div>\n" +
    "                                <div class=\"hide-headings seats-container col-md-1 col-lg-1 font-bold\">2</div>\n" +
    "                                <div class=\"hide-headings seats-container col-md-1 col-lg-1 font-bold\">3</div>\n" +
    "                                <div class=\"hide-headings seats-container col-md-1 col-lg-1 font-bold\">4</div>\n" +
    "                                <div class=\"hide-headings seats-container col-md-1 col-lg-1 font-bold\">5</div>\n" +
    "                                <div class=\"hide-headings seats-container col-md-1 col-lg-1 font-bold\">6</div>\n" +
    "                                <div class=\"hide-headings seats-container col-md-1 col-lg-1 font-bold\">7</div>\n" +
    "                                <div class=\"hide-headings seats-container col-md-1 col-lg-1 font-bold\">8</div>\n" +
    "                            </div>\n" +
    "                            <div class=\"row open-seats seats-row\">\n" +
    "                                <div class=\"hide-headings seats-container col-md-1 col-lg-1 font-bold\">Open Seats</div>\n" +
    "                                <div class=\"seats-container col-md-1 col-lg-1 font-bold\">\n" +
    "                                    <!-- mask=\"99?ZZ\" -->\n" +
    "                                    <h4 class=\"font-bold\">Pre-K</h4>\n" +
    "                                    <input type=\"text\" class=\"form-control\" restrict-input ng-model=\"vm.report.financials.openSeats.preK\" ng-change=\"vm.updateReport()\" maxlength=\"2\">  \n" +
    "                                </div>\n" +
    "                                <div class=\"seats-container col-md-1 col-lg-1 font-bold\">\n" +
    "                                    <h4 class=\"font-bold\">Kindergarten</h4>\n" +
    "                                    <input type=\"text\" class=\"form-control\" restrict-input ng-model=\"vm.report.financials.openSeats.kindergarten\" ng-change=\"vm.updateReport()\" maxlength=\"2\"> \n" +
    "                                </div>\n" +
    "                                <div class=\"seats-container col-md-1 col-lg-1\">\n" +
    "                                    <h4 class=\"font-bold\">First Grade</h4>\n" +
    "                                    <input type=\"text\" class=\"form-control\" restrict-input ng-model=\"vm.report.financials.openSeats.firstGrade\" ng-change=\"vm.updateReport()\" maxlength=\"2\"> \n" +
    "                                </div>\n" +
    "                                <div class=\"seats-container col-md-1 col-lg-1\">\n" +
    "                                    <h4 class=\"font-bold\">Second Grade</h4>\n" +
    "                                    <input type=\"text\" class=\"form-control\" restrict-input ng-model=\"vm.report.financials.openSeats.secondGrade\" ng-change=\"vm.updateReport()\" maxlength=\"2\"> \n" +
    "                                </div>\n" +
    "                                <div class=\"seats-container col-md-1 col-lg-1\">\n" +
    "                                    <h4 class=\"font-bold\">Third Grade</h4>\n" +
    "                                    <input type=\"text\" class=\"form-control\" restrict-input ng-model=\"vm.report.financials.openSeats.thirdGrade\" ng-change=\"vm.updateReport()\" maxlength=\"2\"> \n" +
    "                                </div>\n" +
    "                                <div class=\"seats-container col-md-1 col-lg-1\">\n" +
    "                                    <h4 class=\"font-bold\">Fourth Grade</h4>\n" +
    "                                    <input type=\"text\" class=\"form-control\" restrict-input ng-model=\"vm.report.financials.openSeats.fourthGrade\" ng-change=\"vm.updateReport()\" maxlength=\"2\">\n" +
    "                                </div>\n" +
    "                                <div class=\"seats-container col-md-1 col-lg-1\">\n" +
    "                                    <h4 class=\"font-bold\">Fifth Grade</h4>\n" +
    "                                    <input type=\"text\" class=\"form-control\" restrict-input ng-model=\"vm.report.financials.openSeats.fifthGrade\" ng-change=\"vm.updateReport()\" maxlength=\"2\"> \n" +
    "                                </div>\n" +
    "                                <div class=\"seats-container col-md-1 col-lg-1\">\n" +
    "                                    <h4 class=\"font-bold\">Sixst Grade</h4>\n" +
    "                                    <input type=\"text\" class=\"form-control\" restrict-input ng-model=\"vm.report.financials.openSeats.sixstGrade\" ng-change=\"vm.updateReport()\" maxlength=\"2\"> \n" +
    "                                </div>\n" +
    "                                <div class=\"seats-container col-md-1 col-lg-1\">\n" +
    "                                    <h4 class=\"font-bold\">Seventh Grade</h4>\n" +
    "                                    <input type=\"text\" class=\"form-control\" restrict-input ng-model=\"vm.report.financials.openSeats.seventhGrade\" ng-change=\"vm.updateReport()\" maxlength=\"2\"> \n" +
    "                                </div>\n" +
    "                                <div class=\"seats-container col-md-1 col-lg-1\">\n" +
    "                                    <h4 class=\"font-bold\">Eight Grade</h4>\n" +
    "                                    <input type=\"text\" class=\"form-control\" restrict-input ng-model=\"vm.report.financials.openSeats.eightGrade\" ng-change=\"vm.updateReport()\" maxlength=\"2\"> \n" +
    "                                </div>\n" +
    "                            </div>\n" +
    "                        </div>\n" +
    "                    </div>\n" +
    "                </div>\n" +
    "                <!--ROW-->\n" +
    "            </div>\n" +
    "            <!--CONTAINER-->\n" +
    "\n" +
    "        </uib-accordion>\n" +
    "\n" +
    "        </div>\n" +
    "\n" +
    "    </div>\n" +
    "</div><!--dashboard-bg-->\n" +
    "\n" +
    "\n" +
    "<div class=\"container-fluid background-dark-blue\">\n" +
    "    <div class=\"container footer\">\n" +
    "        <div class=\"row\">\n" +
    "            <div class=\"col-md-6\">\n" +
    "                <div class=\"row\">\n" +
    "                    <p class=\"text-lime fs-16 margin-reset text-left\">Copyright 2016 Catholic Schools Center of Excellence</p>\n" +
    "                </div>\n" +
    "            </div>\n" +
    "            <div class=\"col-md-6\">\n" +
    "                <div class=\"row\">\n" +
    "                    <p class=\"text-lime fs-16 margin-reset text-right text-right-reset\">612.425.1105  |  3033 Excelsior Blvd, Suite 550, Minneapolis, MN 55416</p>\n" +
    "                </div>\n" +
    "            </div>\n" +
    "        </div>\n" +
    "    </div>\n" +
    "</div>\n" +
    "\n" +
    "\n" +
    "")

$templateCache.put("app/dashboard/settings.modal.html"," <div class=\"modal-body clearfix\" id=\"modal-body\">\n" +
    "\n" +
    "        <h1 class=\"col-lg-12 blue-text\">I am comparing my school's<br>academic performance to...</h1>\n" +
    "        <div class=\"col-lg-11\">\n" +
    "            <div class=\"col-lg-6 margin-top-20 margin-bottom-20 form-group label-text\">\n" +
    "                <div class=\"row\">\n" +
    "                    <label class=\"font-normal\">My Comparison</label>\n" +
    "                    <input type=\"text\" class=\"form-control\" ng-model=\"comparison\">\n" +
    "                </div>\n" +
    "            </div>\n" +
    "        </div>\n" +
    "        <div class=\"col-lg-11 padding-bottom-40\">\n" +
    "            <div class=\"row\">\n" +
    "                <div class=\"col-lg-4 margin-top15\">\n" +
    "                    <button type=\"button\" class=\"btn btn-default btn-block fs-16\" ng-click=\"vm.closeModal()\">CANCEL</button>\n" +
    "                </div>\n" +
    "                <div class=\"col-lg-6 margin-top15\">\n" +
    "                    <button class=\"btn btn-primary btn-block padding-20 fs-16\" ng-click=\"vm.updateReport(comparison)\">Save Changes</button>\n" +
    "                </div>\n" +
    "            </div>\n" +
    "        </div>\n" +
    "\n" +
    "</div>\n" +
    "")

$templateCache.put("app/generate/generate.view.html","<div class=\"container\">\n" +
    "    <div class=\"row flex-vertical-center dashboard-logo-container\">\n" +
    "        <div class=\"col-lg-6 col-xs-3\">\n" +
    "            <div class=\"image-background logo-generate\" style=\"background-image: url('{{vm.school.schoolLogo}}')!important;\" ng-cloak></div>\n" +
    "        </div>\n" +
    "        <div class=\"col-lg-6 col-xs-7\" id=\"logo-container\">\n" +
    "            <div class=\"image-background cscoe-logo\" id=\"logo-for-hide\" ng-cloak></div>\n" +
    "        </div>\n" +
    "    </div>\n" +
    "</div>\n" +
    "\n" +
    "<div class=\"container-generate center background-dark-blue border-radius-4\">\n" +
    "    <div class=\"container text-center text-white padding-top-30 padding-bottom-40 pos-relative\">\n" +
    "        <h1 class=\"text-white  uppercase fs-48 neutra font-bold margin-reset\">{{vm.school.name}}</h1>\n" +
    "        <p class=\"text-white uppercase fs-48 neutra font-bold margin-reset\">{{vm.report.timespan}} SNAPSHOT REPORT</p>\n" +
    "    </div>\n" +
    "</div>\n" +
    "\n" +
    "<div class=\"container pos-relative\">\n" +
    "    <img src=\"images/pencil.svg\" class=\"img-responsive pencil-bottom\">\n" +
    "</div>\n" +
    "\n" +
    "<div class=\"container\">\n" +
    "    <div class=\"row margin-bottom-50\">\n" +
    "        <div class=\"col-lg-12\">\n" +
    "            <div class=\"row\">\n" +
    "                <div class=\"col-lg-12\">\n" +
    "                    <h1 class=\"blue-text-light font-bold margin-top-60\">Academics</h1>\n" +
    "                    <h1 class=\"pos-relative blue-text-light font-bold fs-30\">STATUS: <span class=\"pos-absolute generate icon-{{vm.report.academics.status}}\"></span></h1>\n" +
    "                </div>\n" +
    "            </div>\n" +
    "        </div>\n" +
    "        <div class=\"col-md-6\">\n" +
    "            <div class=\"row\">\n" +
    "                <div class=\"col-lg-12\">\n" +
    "                    <h2 class=\"blue-text neutra font-bold fs-30\">READING PROFICIENCY</h2>\n" +
    "                    <div class=\"col-lg-12\">\n" +
    "                        <div class=\"row\">\n" +
    "                            <label class=\"fs-16 font-bold blue-text\">My School</label>\n" +
    "                        </div>\n" +
    "                        <div class=\"row margin-bottom-20 \">\n" +
    "                            <div class=\"m-width-315\">\n" +
    "                                <div class=\"blue-percent pull-left\" ng-style=\"{width: vm.report.academics.reading.myschool + '%'}\"></div>\n" +
    "                            </div>\n" +
    "                            <span class=\"pull-left text-lime font-bold neutra fs-42 margin-left-15 lh-25\">{{vm.report.academics.reading.myschool ? (vm.report.academics.reading.myschool | numberConvertor) : 0}}%</span>\n" +
    "                        </div>\n" +
    "                        <div class=\"row\">\n" +
    "                            <label class=\"fs-16 font-bold blue-text\">{{vm.report.comparison}}</label>\n" +
    "                        </div>\n" +
    "                         <div class=\"row\">\n" +
    "                            <div class=\"m-width-315\">\n" +
    "                                <div class=\"blue-percent pull-left\" ng-style=\"{width: vm.report.academics.reading.comparison + '%'}\"></div>\n" +
    "                            </div>\n" +
    "                            <span class=\"pull-left text-lime font-bold neutra fs-42 margin-left-15 lh-25\">{{vm.report.academics.reading.comparison ? (vm.report.academics.reading.comparison | numberConvertor) : 0}}%</span>\n" +
    "                        </div>\n" +
    "                    </div>\n" +
    "                </div>\n" +
    "            </div>\n" +
    "        </div>\n" +
    "        <div class=\"col-md-6\">\n" +
    "            <div class=\"row margin-left-45\">\n" +
    "                <h2 class=\"blue-text neutra font-bold fs-30\">MATH PROFICIENCY</h2>\n" +
    "                <div class=\"col-lg-12\">\n" +
    "                    <div class=\"row\">\n" +
    "                        <label class=\"fs-16 font-bold blue-text\">My School</label>\n" +
    "                    </div>\n" +
    "                    <div class=\"row margin-bottom-20 \">\n" +
    "                        <div class=\"m-width-315\">\n" +
    "                            <div class=\"blue-percent pull-left\" ng-style=\"{width: vm.report.academics.mathematics.myschool + '%'}\"></div>\n" +
    "                        </div>\n" +
    "                        <span class=\"pull-left text-lime font-bold neutra fs-42 margin-left-15 lh-25\">{{vm.report.academics.mathematics.myschool ? (vm.report.academics.mathematics.myschool | numberConvertor) : 0}}%</span>\n" +
    "                    </div>\n" +
    "                    <div class=\"row\">\n" +
    "                        <label class=\"fs-16 font-bold blue-text\">{{vm.report.comparison}}</label>\n" +
    "                    </div>\n" +
    "                     <div class=\"row\">\n" +
    "                        <div class=\"m-width-315\">\n" +
    "                            <div class=\"blue-percent pull-left\" ng-style=\"{width: vm.report.academics.mathematics.comparison + '%'}\"></div>\n" +
    "                        </div>\n" +
    "                        <span class=\"pull-left text-lime font-bold neutra fs-42 margin-left-15 lh-25\">{{vm.report.academics.mathematics.comparison  ? (vm.report.academics.mathematics.comparison | numberConvertor) : 0}}%</span>\n" +
    "                    </div>\n" +
    "                </div>\n" +
    "            </div>\n" +
    "        </div>\n" +
    "    </div>\n" +
    "</div>\n" +
    "\n" +
    "<div class=\"container\">\n" +
    "    <img src=\"images/pencil.svg\" class=\"img-responsive margin-top-60\">\n" +
    "</div>\n" +
    "\n" +
    "<div class=\"container margin-top-80\">\n" +
    "    <div class=\"row\">\n" +
    "        <div class=\"col-lg-6 border-right-dashed\">\n" +
    "            <div class=\"col-lg-12\">\n" +
    "                <div class=\"row\">\n" +
    "                    <h1 class=\"blue-text-light font-bold\">Formation</h1>\n" +
    "                    <h1 class=\"pos-relative blue-text-light font-bold fs-30\">STATUS: <span class=\"pos-absolute generate icon-{{vm.report.formation.status}}\"></span></h1>\n" +
    "                    <h1 class=\"blue-text-light font-bold fs-30\">GOAL:</h1>\n" +
    "                    <h1 class=\"blue-text-light\">{{vm.report.formation.goal}}</h1>\n" +
    "                    <h1 class=\"blue-text-light font-bold fs-30\">DAYS / WEEK:</h1>\n" +
    "                    <h1 class=\"text-lime neutra font-bold fs-100 margin-reset lh-100\">{{vm.report.formation.hours}}<br>DAYS/<br>WEEK</h1>\n" +
    "                </div>\n" +
    "            </div>\n" +
    "        </div>\n" +
    "        <div class=\"col-lg-6\">\n" +
    "            <div class=\"col-lg-12\">\n" +
    "                <div class=\"row margin-left-45\">\n" +
    "                    <h1 class=\"blue-text-light font-bold\">Satisfaction</h1>\n" +
    "                    <h1 class=\"pos-relative blue-text-light font-bold fs-30\">STATUS: <span class=\"pos-absolute generate icon-{{vm.report.satisfaction.status}}\"></span></h1>\n" +
    "                    <h1 class=\"blue-text-light font-bold fs-30\">GOAL:</h1>\n" +
    "                    <h1 class=\"blue-text-light\">{{vm.report.satisfaction.goal}}</h1>\n" +
    "                    <h1 class=\"blue-text-light font-bold fs-30\">RETENTION RATE:</h1>\n" +
    "                    <div class=\"light-green-bg\">\n" +
    "                        <h1 class=\"fs-100 neutra font-bold text-white\">{{vm.report.satisfaction.retention}}%</h1>\n" +
    "                    </div>\n" +
    "                </div>\n" +
    "            </div>\n" +
    "        </div>\n" +
    "    </div>\n" +
    "</div>\n" +
    "\n" +
    "<div class=\"container margin-top-40\">\n" +
    "    <img src=\"images/pencil.svg\" class=\"img-responsive margin-top-60\">\n" +
    "    <div class=\"row margin-top-40 margin-bottom-40\">\n" +
    "        <div class=\"col-lg-12 page-info-margin\">\n" +
    "            <div class=\"col-lg-6 col-md-2 col-sm-2 col-xs-12\">\n" +
    "                <div class=\"row\">\n" +
    "                    <p class=\"fs-16 font-bold blue-text text-left\">Page 1 of 2</p>\n" +
    "                </div>\n" +
    "            </div>\n" +
    "            <div class=\"col-lg-6 col-md-10 col-sm-10 col-xs-12\">\n" +
    "                <div class=\"row\">\n" +
    "                    <p class=\"fs-16 font-bold blue-text text-right text-right-reset\">{{vm.school.name}} and CSCOE Private and Confidential</p>\n" +
    "                </div>\n" +
    "            </div>\n" +
    "        </div>\n" +
    "    </div>\n" +
    "</div>\n" +
    "\n" +
    "    <div class=\"js-chapter\"></div>\n" +
    "\n" +
    "<div class=\"container\">\n" +
    "\n" +
    "    <div class=\"row flex-vertical-center dashboard-logo-container\">\n" +
    "        <div class=\"col-lg-6 col-xs-3\">\n" +
    "            <div class=\"image-background logo-generate\" style=\"background-image: url('{{vm.school.schoolLogo}}')!important;\" ng-cloak></div>\n" +
    "        </div>\n" +
    "        <div class=\"col-lg-6 col-xs-7\" id=\"logo-container2\">\n" +
    "            <div class=\"image-background cscoe-logo\" id=\"logo-for-hide2\" ng-cloak></div>\n" +
    "        </div>\n" +
    "    </div>\n" +
    "</div>\n" +
    "\n" +
    "<div class=\"container-generate center background-dark-blue border-radius-4\">\n" +
    "    <div class=\"container text-center text-white padding-top-30 padding-bottom-40 pos-relative\">\n" +
    "        <h1 class=\"text-white uppercase fs-48 neutra font-bold margin-reset\">{{vm.school.name}}</h1>\n" +
    "        <p class=\"text-white uppercase fs-48 neutra font-bold margin-reset\">{{vm.report.timespan}} SNAPSHOT REPORT</p>\n" +
    "    </div>\n" +
    "</div>\n" +
    "\n" +
    "<div class=\"container pos-relative\">\n" +
    "    <img src=\"images/pencil.svg\" class=\"img-responsive pencil-bottom \">\n" +
    "</div>\n" +
    "\n" +
    "<div class=\"container\">\n" +
    "    <div class=\"row\">\n" +
    "        <div class=\"col-lg-12\">\n" +
    "            <h1 class=\"blue-text-light font-bold margin-top-60\">Enrollment</h1>\n" +
    "            <h1 class=\"pos-relative blue-text-light font-bold fs-30\">STATUS: <span class=\"pos-absolute generate icon-{{vm.report.enrollment.status}}\"></span></h1>\n" +
    "            <h1 class=\"blue-text-light font-bold fs-30\">GOAL:</h1>\n" +
    "            <h1 class=\"blue-text-light\">{{vm.report.enrollment.goal}}</h1>\n" +
    "        </div>\n" +
    "        <div class=\"col-lg-12\">\n" +
    "            <div class=\"row\">\n" +
    "                <div class=\"col-lg-6\">\n" +
    "                    <div class=\"row\">\n" +
    "                    <div class=\"col-lg-12\">\n" +
    "                            <h2 class=\"blue-text neutra font-bold fs-30\">PRE-K-GROWTH IF APPLICABLE</h2>\n" +
    "                            <h1 class=\"text-lime neutra font-bold fs-100 margin-bottom-40\">{{Math.round(vm.report.totalEnrollment)}}%</h1>\n" +
    "                            <div class=\"col-lg-12\">\n" +
    "                                <div class=\"row\">\n" +
    "                                   <label class=\"fs-16 font-bold blue-text\">{{vm.report.timespan}}</label>\n" +
    "                                </div>\n" +
    "                                <div class=\"row margin-bottom-20 \">\n" +
    "                                    <div class=\"m-width-315\">\n" +
    "                                        <div class=\"blue-percent pull-left\" ng-style=\"{width: vm.currentYearGrowthPercentage + '%'}\"></div>\n" +
    "                                    </div>\n" +
    "                                    <span class=\"pull-left text-lime font-bold neutra fs-42 margin-left-15 lh-25\">{{vm.report.enrollment.currentYearEnrollment}}</span>\n" +
    "                                </div>\n" +
    "                                <div class=\"row\">\n" +
    "                                    <label class=\"fs-16 font-bold blue-text\">{{vm.report.lastYear}}</label>\n" +
    "                                </div>\n" +
    "                                 <div class=\"row\">\n" +
    "                                    <div class=\"m-width-315\">\n" +
    "                                        <div class=\"blue-percent pull-left\" ng-style=\"{width: vm.lastYearGrowthPercentage + '%'}\"></div>\n" +
    "                                    </div>\n" +
    "                                    <span class=\"pull-left text-lime font-bold neutra fs-42 margin-left-15 lh-25\">{{vm.report.enrollment.lastYearEnrollment}}</span>\n" +
    "                                </div>\n" +
    "                            </div>\n" +
    "                        </div>\n" +
    "                    </div>\n" +
    "                </div>\n" +
    "                <div class=\"col-lg-6 js-graph\">\n" +
    "                    <h2 class=\"blue-text neutra font-bold fs-30 margin-bottom-90\">TOTAL SCHOOL 5-YEAR TREND</h2>\n" +
    "                    <div class=\"svg-container\" style=\"max-height: 195px\">\n" +
    "                      <linechart data=\"data\" options=\"options\"></linechart>\n" +
    "                    </div>\n" +
    "                    <div class=\"row\">\n" +
    "                        <div class=\"col-lg-12\">\n" +
    "                            <div ng-repeat=\"data in data.dataset0\" class=\"display-inline-block text-center graph-x\">\n" +
    "                                <span class=\"show fs-42 text-lime neutra font-bold\">{{data.enrollmentNum}}</span>\n" +
    "                                <span class=\"show fs-14 text-dark-green font-bold five-y-trend-dates\">{{data.labelYear}}</span>\n" +
    "                            </div>\n" +
    "                        </div>\n" +
    "                    </div>\n" +
    "                </div>\n" +
    "            </div>\n" +
    "        </div>\n" +
    "    </div>\n" +
    "</div>\n" +
    "\n" +
    "<div class=\"container\">\n" +
    "    <img src=\"images/pencil.svg\" class=\"img-responsive margin-top-60 margin-bottom-40\">\n" +
    "</div>\n" +
    "\n" +
    "<div class=\"container\">\n" +
    "    <div class=\"row\">\n" +
    "        <div class=\"col-lg-12\">\n" +
    "            <h1 class=\"blue-text-light font-bold\">Financials</h1>\n" +
    "            <h1 class=\"pos-relative blue-text-light font-bold fs-30\">STATUS: <span class=\"pos-absolute generate icon-{{vm.report.financials.status}}\"></span></h1>\n" +
    "            <h1 class=\"pos-relative blue-text-light font-bold fs-30\">CURRENTLY {{vm.report.financials.hasOpenSeats ? \"ADMITTING STUDENTS\" : \"AT CAPACITY\"}}</h1>\n" +
    "            <div class=\"col-lg-11\">\n" +
    "                <div class=\"row\">\n" +
    "                    <h1 class=\"col-lg-6 blue-text-light font-bold text-left fs-30\">\n" +
    "                        <div class=\"row\">\n" +
    "                            INCOME:\n" +
    "                        </div>\n" +
    "                    </h1>\n" +
    "                    <h1 class=\"col-lg-6 blue-text-light font-bold text-right fs-30\">\n" +
    "                        <div class=\"text-right mobile-display-none margin-right-9 print-expenses\">\n" +
    "                            EXPENSES:\n" +
    "                        </div>\n" +
    "                    </h1>\n" +
    "                </div>\n" +
    "            </div>\n" +
    "            <div class=\"col-lg-12 margin-top-10\">\n" +
    "                <div class=\"row\">\n" +
    "                    <div class=\"col-lg-3\">\n" +
    "                        <div class=\"row\">\n" +
    "                            <label class=\"fs-16 show font-bold blue-text\">Tuition</label>\n" +
    "                            <span class=\"fs-50 font-bold text-lime\">${{vm.report.financials.income.tuition ? (vm.report.financials.income.tuition | number:0) : 0}}</span>\n" +
    "                        </div>\n" +
    "                    </div>\n" +
    "                    <div class=\"col-lg-3\">\n" +
    "                        <div class=\"row\">\n" +
    "                            <label class=\"fs-16 show font-bold blue-text\">Fundraising</label>\n" +
    "                            <span class=\"fs-50 font-bold text-lime\">${{vm.report.financials.income.fundraising ? (vm.report.financials.income.fundraising | number:0) : 0}}</span>\n" +
    "                        </div>\n" +
    "                    </div>\n" +
    "                    <div class=\"col-lg-3\">\n" +
    "                        <div class=\"row\">\n" +
    "                            <label class=\"fs-16 show font-bold blue-text\">Parish Support</label>\n" +
    "                            <span class=\"fs-50 font-bold text-lime\">${{vm.report.financials.income.parishsupport ? (vm.report.financials.income.parishsupport | number:0) : 0}}</span>\n" +
    "                        </div>\n" +
    "                    </div>\n" +
    "                    <div class=\"col-lg-3\">\n" +
    "                        <h1 class=\"blue-text-light font-bold fs-30 mobile-display-block print-move-up\">\n" +
    "                            <div class=\"row\">\n" +
    "                                EXPENSES:\n" +
    "                            </div>\n" +
    "                        </h1>\n" +
    "                        <div class=\"row\" id=\"fix-expenses-margin-chrome-ff\">\n" +
    "                            <label class=\"fs-16 show font-bold blue-text\">Cost per Student</label>\n" +
    "                            <span class=\"fs-50 font-bold text-lime\">${{vm.report.financials.costPerStudent ? (vm.report.financials.costPerStudent | number:0) : 0}}</span>\n" +
    "                        </div>\n" +
    "                    </div>\n" +
    "                </div>\n" +
    "            </div>\n" +
    "            <div class=\"col-lg-12\">\n" +
    "                <div class=\"row\">\n" +
    "                    <img src=\"images/pencil.svg\" class=\"img-responsive margin-top-60\">\n" +
    "                </div>\n" +
    "            </div>\n" +
    "\n" +
    "            <div class=\"col-lg-12 page-info-margin\">\n" +
    "                <div class=\"row margin-top-40 margin-bottom-40\">\n" +
    "                    <div class=\"col-lg-12\">\n" +
    "                        <div class=\"col-lg-6 col-md-2 col-sm-2 col-xs-12\">\n" +
    "                            <div class=\"row\">\n" +
    "                                <p class=\"fs-16 font-bold blue-text text-left\">Page 2 of 2</p>\n" +
    "                            </div>\n" +
    "                        </div>\n" +
    "                        <div class=\"col-lg-6 col-md-10 col-sm-10 col-xs-12\">\n" +
    "                            <div class=\"row\">\n" +
    "                                <p class=\"fs-16 font-bold blue-text text-right text-right-reset\">{{vm.school.name}} and CSCOE Private and Confidential</p>\n" +
    "                            </div>\n" +
    "                        </div>\n" +
    "                    </div>\n" +
    "                </div>\n" +
    "            </div>\n" +
    "        </div>\n" +
    "    </div>\n" +
    "</div>\n" +
    "")

$templateCache.put("app/login/login.view.html","<div class=\"pencil-bg\">\n" +
    "  <div class=\"container flex login-container\">\n" +
    "    <div class=\"col-lg-4 col-xs-12 center login-box\">\n" +
    "        <h3 class=\"text-dark-green font-bold\">Log In</h3>\n" +
    "        <form ng-submit=\"vm.login(loginForm)\" name=\"loginForm\" novalidate=\"true\">\n" +
    "          <div class=\"form-group\">\n" +
    "            <label class=\"text-dark-green\">Email</label>\n" +
    "            <input type=\"email\" name=\"email\" class=\"form-control\" placeholder=\"example@mail.com\" ng-model=\"vm.user.email\" required>\n" +
    "            <div ng-messages=\"loginForm.email.$error\" ng-if=\"loginForm.email.$dirty || loginForm.$submitted\">\n" +
    "                <div class=\"text-danger\" ng-message=\"required\">This field is required!</div>\n" +
    "                <div class=\"text-danger\" ng-message=\"email\">Your email address is invalid</div>\n" +
    "            </div>\n" +
    "          </div>\n" +
    "          <div class=\"form-group\">\n" +
    "            <label class=\"text-dark-green\">Password</label>\n" +
    "            <input type=\"password\" name=\"password\" class=\"form-control\" placeholder=\"enter password\" ng-model=\"vm.user.password\" required>\n" +
    "             <div ng-messages=\"loginForm.password.$error\" ng-if=\"loginForm.password.$dirty || loginForm.$submitted\">\n" +
    "                <div class=\"text-danger\" ng-message=\"required\">This field is required!</div>\n" +
    "            </div>\n" +
    "          </div>\n" +
    "          <div class=\"form-group col-lg-12\">\n" +
    "            <div class=\"row\">\n" +
    "              <button type=\"submit\" class=\"btn btn-orange-login padding-20 btn-block\">Log In</button>\n" +
    "            </div>\n" +
    "          </div>\n" +
    "          <div class=\"col-lg-12\">\n" +
    "            <div class=\"row\">\n" +
    "              <a class=\"btn btn-link show padding-bottom-20\" ng-click=\"vm.forgotPassword()\">Forgot Password</a>\n" +
    "            </div>\n" +
    "          </div>\n" +
    "          <div class=\"alert alert-danger col-lg-12\" role=\"alert\" ng-if=\"vm.loginErrShow\">\n" +
    "            {{vm.loginErrMessage}}\n" +
    "          </div>\n" +
    "        </form>\n" +
    "    </div>\n" +
    "  </div>\n" +
    "</div>\n" +
    "")

$templateCache.put("app/passwordreset/passwordreset.view.html","<div class=\"pencil-bg\">\n" +
    "  <div class=\"container flex login-container\" ng-if=\"resetPasswordReady\">\n" +
    "      <div class=\"col-lg-4 col-xs-12 center login-box\">\n" +
    "          <h3 class=\"text-dark-green font-bold\">New Password</h3>\n" +
    "          <form ng-submit=\"vm.resetPassword(resetPasswordForm)\" name=\"resetPasswordForm\" novalidate=\"true\">\n" +
    "            <div class=\"form-group\">\n" +
    "              <label class=\"text-dark-green\">Email</label>\n" +
    "              <input id=\"email\" type=\"email\" class=\"form-control\" placeholder=\"example@mail.com\" ng-model=\"vm.user.email\" disabled=\"disabled\">\n" +
    "            </div>\n" +
    "            <div class=\"form-group\">\n" +
    "              <label class=\"text-dark-green\">Password</label>\n" +
    "              <input type=\"password\" name=\"password\" class=\"form-control\" placeholder=\"enter password\" ng-model=\"vm.user.password\" minlength=\"6\" required>\n" +
    "               <div ng-messages=\"resetPasswordForm.password.$error\" ng-if=\"resetPasswordForm.password.$dirty || resetPasswordForm.$submitted\">\n" +
    "                  <div class=\"text-danger\" ng-message=\"required\">This field is required!</div>\n" +
    "                  <div class=\"text-danger\" ng-message=\"minlength\">Password must be at least 6 symbols!</div>\n" +
    "              </div>\n" +
    "            </div>\n" +
    "            <div class=\"form-group\">\n" +
    "              <label class=\"text-dark-green\">Confirm Password</label>\n" +
    "              <input type=\"password\" name=\"confirmedpassword\" class=\"form-control\" placeholder=\"Confirm password\" minlength=\"6\" ng-model=\"vm.user.confirmed_password\" compare-to=\"vm.user.password\" required>\n" +
    "              <div ng-messages=\"resetPasswordForm.confirmedpassword.$error\" ng-if=\"resetPasswordForm.confirmedpassword.$dirty || resetPasswordForm.$submitted\">\n" +
    "                  <div class=\"text-danger\" ng-message=\"required\">This field is required!</div>\n" +
    "                  <div class=\"text-danger\" ng-message=\"compareTo\">Passwords don't match!</div>\n" +
    "                  <div class=\"text-danger\" ng-message=\"minlength\">Password must be at least 6 symbols!</div>\n" +
    "              </div>\n" +
    "            </div>\n" +
    "            <div class=\"form-group col-lg-12\">\n" +
    "              <div class=\"row\">\n" +
    "                <button type=\"submit\" class=\"btn btn-orange-login padding-20 btn-block\">Reset Password</button>\n" +
    "              </div>\n" +
    "            </div>\n" +
    "          </form>\n" +
    "          <div class=\"alert alert-danger col-lg-12\" role=\"alert\" ng-if=\"passErrShow\">\n" +
    "              {{vm.passErrMessage}}\n" +
    "          </div>\n" +
    "      </div>\n" +
    "  </div>\n" +
    "</div>\n" +
    "")

$templateCache.put("app/noauthsplash/noauthsplash.view.html","<div class=\"container flex login-container\">\n" +
    "    <div class=\"col-lg-4 text-center center\">\n" +
    "        <h3>Password reset email sent</h3>\n" +
    "        <p>Please check your email.</p>\n" +
    "\n" +
    "    </div>\n" +
    "</div>\n" +
    "")

$templateCache.put("app/splash/splash.view.html","<div class=\"pencil-bg\">\n" +
    "    <div class=\"container flex login-container\">\n" +
    "        <div class=\"col-lg-6 col-xs-12 center login-box\">\n" +
    "            <div ng-if=\"!vm.hideForm\">\n" +
    "                <h3 class=\"text-dark-green font-bold\">{{vm.splashHeader}}</h3>\n" +
    "                <p class=\"fs-18\">{{vm.splashInformation}}</p>\n" +
    "\n" +
    "                <button class=\"btn btn-primary padding-20 margin-bottom-20\" ng-click=\"vm.logout()\" ng-if=\"!vm.notVerified\">Log In with another user</button>\n" +
    "\n" +
    "                <div class=\"margin-top-20 margin-bottom-20 row\" ng-if=\"vm.notVerified\">\n" +
    "                    <p class=\"font-bold col-lg-12 fs-18\">If you have not recieved any verification email, please click on Resend Verification button below.<br>Be patient, it can take a while!</p>\n" +
    "                    <div class=\"col-md-6\">\n" +
    "                        <button class=\"btn btn-block background-dark-green padding-20 text-white border-radius-none\" ng-click=\"vm.resendVerificationLink()\">Resend Verification Link</button>\n" +
    "                    </div>\n" +
    "                    <div class=\"col-md-6\">\n" +
    "                        <button class=\"btn btn-block btn-primary padding-20\" ng-click=\"vm.logout()\">Log In with another user</button>\n" +
    "                    </div>\n" +
    "                    <p class=\"col-lg-12 margin-top-20\">Note: In case you have few Emails, use the latest link. All previous links will be invalid!</p>\n" +
    "                </div>\n" +
    "            </div>\n" +
    "\n" +
    "            <div ng-if=\"vm.hideForm\">\n" +
    "                <div class=\"alert alert-success margin-top-20 col-lg-12\">\n" +
    "                    <h2>Verification link has been successfuly resent to your Email!</h2>\n" +
    "                    <p class=\"margin-bottom-20\">After you verify your email you can <span class=\"text-link font-bold cursor-pointer\" ng-click=\"vm.logout()\">Log In</span>  with your account to preview your school's dashboard.</p>\n" +
    "\n" +
    "                    <button class=\"btn btn-success btn-block\"  ng-click=\"vm.logout()\">Log In</button>\n" +
    "                </div>\n" +
    "            </div>\n" +
    "\n" +
    "        </div>\n" +
    "\n" +
    "\n" +
    "    </div>\n" +
    "</div>\n" +
    "")

$templateCache.put("app/verifyemail/verifyemail.view.html","<div class=\"pencil-bg\">\n" +
    "<div class=\"container flex login-container\">\n" +
    "\n" +
    "        <div class=\"col-lg-6 col-xs-12 center login-box\" ng-if=\"vm.errorInvalid\">\n" +
    "            <h3 class=\"text-dark-green font-bold text-center\">{{vm.error.message}}</h3>\n" +
    "\n" +
    "            <button class=\"btn btn-primary btn-block padding-20 margin-bottom-20\" ui-sref=\"login\">Go to Login page</button>\n" +
    "        </div>\n" +
    "\n" +
    "        <div class=\"col-lg-4 col-xs-12 center login-box\" ng-if=\"vm.errorExpired\">\n" +
    "            <div ng-if=\"!vm.hideForm\">\n" +
    "                <h3 class=\"text-dark-green font-bold\">Resend verification link!</h3>\n" +
    "                <form ng-submit=\"vm.login(loginForm)\" name=\"loginForm\" novalidate=\"true\">\n" +
    "                  <div class=\"form-group\">\n" +
    "                    <label class=\"text-dark-green\">Email</label>\n" +
    "                    <input type=\"email\" name=\"email\" class=\"form-control\" placeholder=\"example@mail.com\" ng-model=\"vm.user.email\" required>\n" +
    "                    <div ng-messages=\"loginForm.email.$error\" ng-if=\"loginForm.email.$dirty || loginForm.$submitted\">\n" +
    "                        <div class=\"text-danger\" ng-message=\"required\">This field is required!</div>\n" +
    "                        <div class=\"text-danger\" ng-message=\"email\">Your email address is invalid</div>\n" +
    "                    </div>\n" +
    "                  </div>\n" +
    "                  <div class=\"form-group\">\n" +
    "                    <label class=\"text-dark-green\">Password</label>\n" +
    "                    <input type=\"password\" name=\"password\" class=\"form-control\" placeholder=\"enter password\" ng-model=\"vm.user.password\" required>\n" +
    "                     <div ng-messages=\"loginForm.password.$error\" ng-if=\"loginForm.password.$dirty || loginForm.$submitted\">\n" +
    "                        <div class=\"text-danger\" ng-message=\"required\">This field is required!</div>\n" +
    "                    </div>\n" +
    "                  </div>\n" +
    "                  <div class=\"form-group col-lg-12\">\n" +
    "                    <div class=\"row\">\n" +
    "                      <button type=\"submit\" class=\"btn btn-orange-login padding-20 btn-block\" ng-disabled=\"vm.disableButton\" ng-class=\"{disabled: vm.disableButton}\">RESEND VERIFICATION LINK</button>\n" +
    "                    </div>\n" +
    "                  </div>\n" +
    "                  <div class=\"col-lg-12\">\n" +
    "                    <div class=\"row\">\n" +
    "                      <a class=\"btn btn-link show padding-bottom-20\" ng-click=\"vm.forgotPassword()\">Forgot Password</a>\n" +
    "                    </div>\n" +
    "                  </div>\n" +
    "                  <div class=\"alert alert-danger col-lg-12\" role=\"alert\" ng-if=\"vm.loginErrShow\">\n" +
    "                    {{vm.loginErrMessage.message || vm.loginErrMessage}}\n" +
    "                  </div>\n" +
    "                </form>\n" +
    "            </div>\n" +
    "            <div class=\"alert alert-success margin-top-20 col-lg-12\" ng-if=\"vm.hideForm\">\n" +
    "                <h2>Verification link has been successfuly resent to your Email!</h2>\n" +
    "                <p class=\"margin-bottom-20\">After you verify your email you can <span class=\"text-link font-bold cursor-pointer\" ng-click=\"vm.logout()\">Log In</span>  with your account to preview your school's dashboard.</p>\n" +
    "\n" +
    "                <button class=\"btn btn-success btn-block\"  ng-click=\"vm.logout()\">Log In</button>\n" +
    "\n" +
    "            </div>\n" +
    "        </div>\n" +
    "\n" +
    "</div>\n" +
    "</div>\n" +
    "")

$templateCache.put("app/register/register.view.html","<div class=\"pencil-bg\">\n" +
    "  <div class=\"container flex login-container\">\n" +
    "      <div class=\"col-lg-4 col-xs-12 center login-box\">\n" +
    "          <h3 class=\"text-dark-green font-bold\">Sign Up</h3>\n" +
    "          <form ng-submit=\"vm.signUp(registerForm)\" novalidate=\"true\" name=\"registerForm\">\n" +
    "            <div class=\"form-group\">\n" +
    "              <label class=\"text-dark-green\">Email</label>\n" +
    "              <input type=\"email\" name=\"email\" class=\"form-control\" placeholder=\"example@mail.com\" ng-model=\"vm.user.email\" required>\n" +
    "              <div ng-messages=\"registerForm.email.$error\" ng-if=\"registerForm.email.$dirty || registerForm.$submitted\">\n" +
    "                  <div class=\"text-danger\" ng-message=\"required\">This field is required!</div>\n" +
    "                  <div class=\"text-danger\" ng-message=\"email\">Your email address is invalid</div>\n" +
    "              </div>\n" +
    "            </div>\n" +
    "            <div class=\"form-group\">\n" +
    "              <label class=\"text-dark-green\">Password</label>\n" +
    "              <input type=\"password\" name=\"password\" class=\"form-control\" placeholder=\"Enter password\" ng-model=\"vm.user.password\" minlength=\"6\" required>\n" +
    "               <div ng-messages=\"registerForm.password.$error\" ng-if=\"registerForm.password.$dirty || registerForm.$submitted\">\n" +
    "                  <div class=\"text-danger\" ng-message=\"required\">This field is required!</div>\n" +
    "                  <div class=\"text-danger\" ng-message=\"minlength\">Password must be at least 6 symbols!</div>\n" +
    "              </div>\n" +
    "            </div>\n" +
    "            <div class=\"form-group\">\n" +
    "              <label class=\"text-dark-green\">Confirm Password</label>\n" +
    "              <input type=\"password\" name=\"confirmedpassword\" class=\"form-control\" placeholder=\"Confirm password\" ng-model=\"vm.user.confirmedpassword\" minlength=\"6\" required compare-to=\"vm.user.password\">\n" +
    "              <div ng-messages=\"registerForm.confirmedpassword.$error\" ng-if=\"registerForm.confirmedpassword.$dirty || registerForm.$submitted\">\n" +
    "                  <div class=\"text-danger\" ng-message=\"required\">This field is required!</div>\n" +
    "                  <div class=\"text-danger\" ng-message=\"compareTo\">Passwords don't match!</div>\n" +
    "                  <div class=\"text-danger\" ng-message=\"minlength\">Password must be at least 6 symbols!</div>\n" +
    "              </div>\n" +
    "            </div>\n" +
    "            <div class=\"form-group col-lg-12\">\n" +
    "              <div class=\"row\">\n" +
    "                <button type=\"submit\" class=\"btn btn-orange-login padding-20 btn-block\">Sign Up</button>\n" +
    "              </div>\n" +
    "            </div>\n" +
    "            <div class=\"alert alert-danger col-lg-12\" role=\"alert\" ng-if=\"vm.registerErrMessage\">\n" +
    "              {{vm.registerErrMessage}}\n" +
    "            </div>\n" +
    "          </form>\n" +
    "      </div>\n" +
    "  </div>\n" +
    "</div>\n" +
    "")
}]);
})();