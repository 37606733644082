(function() {
    'use strict';

    angular
    .module('cscoe.login', [])
    .controller('LoginCtrl', LoginCtrl);

    /* @ngInject */
    function LoginCtrl($scope, $state, AuthService, ErrorMessageService, SchoolService, $timeout) {
        var vm = this;

        activate();


        ////////////////

        function activate() {
            vm.login = login;
            vm.forgotPassword = forgotPassword;
        }

        function login(form) {
            if (form.$valid) {
                hideErrorMessage();
                AuthService.login(vm.user)
                .then(function(userIsAdmin) {
                    if (userIsAdmin) {
                        $state.go('admin');
                    } else {
                        SchoolService.checkIfEmailExist(vm.user.email)
                        .then(function(res) {
                            if (res) {
                                $state.go('dashboard', {schoolId: res.$id});
                            }else {
                                $state.go('splash', {
                                    splashInformation: 'Sorry! Your e-mail isn\'t associated with a school. Contact CSCOE for more information.',
                                    splashHeader: 'User has no school'
                                });
                            }
                        });

                    }
                })
                .catch(function(err) {
                    console.log(err);
                    vm.loginErrMessage = ErrorMessageService.getUserError(err);
                    vm.loginErrShow = true;
                });

            }

        }

        function forgotPassword() {
            if (vm.user && vm.user.email !== '' &&  vm.user.email !== undefined) {
                hideErrorMessage();
                AuthService.sendPasswordResetEmail(vm.user.email)
                    .then(function() {
                        $state.go('noauthsplash');
                    }).catch(function (err) {
                        vm.loginErrMessage = err.message;
                        vm.loginErrShow = true;
                        $scope.$apply();
                    });
            } else {
                vm.loginErrMessage = 'Please enter first your email address and then click "Forgot password".';
                vm.loginErrShow = true;
            }
        }

        function hideErrorMessage() {
            vm.loginErrShow = false;
        }

    }
})();
