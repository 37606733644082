(function() {
    'use strict';

    angular
        .module('appCore.services', [])
        .factory('LocalStorage', LocalStorage);

    /* @ngInject */
    function LocalStorage(dependencies) {
        var service = {
            set: set,
            get: get
        };

        return service;

        function set(key,val) {
            $window.localStorage[key] = (typeof val === 'object') ? JSON.stringify(val) : val;
            /*$rootScope.$broadcast('LocalStorage.notification.set', { key:key, val: val });*/
        }

        function get(key) {
            var val = 'derp';
            try {
                val = JSON.parse($window.localStorage[key]);
            } catch (err) {
                val = $window.localStorage[key];
            }

            return val;
        }
    }
})();
