(function() {
    'use strict';

    angular
    .module('cscoe.splash', [])
    .controller('SplashCtrl', SplashCtrl);

    /* @ngInject */
    function SplashCtrl($scope, $state, AuthService) {
        var vm = this;
        vm.logout = logout;
        vm.resendVerificationLink = resendVerificationLink;

        activate();

        ////////////////

        function activate() {
            vm.splashInformation = $state.params.splashInformation || 'Please go check your email to verify your account!';
            vm.splashHeader = $state.params.splashHeader || 'User not verified';
            if ($state.params.splashInformation === "You must verify your email." && $state.params.splashHeader === "User not verified") {
                vm.notVerified = true;
            }
        }

        function resendVerificationLink() {
            var currentAuthObject = AuthService.getCurrentUser();
            vm.hideForm = true;
            currentAuthObject.sendEmailVerification()
                .then(function () {
                    console.log('verification link sent');
                })
                .catch(function (err) {
                   console.log(err);
                });
        }

        function logout() {
            AuthService.logout()
                .then(function (argument) {
                    $state.go('login');
                });
        }
    }
})();
