(function() {
    'use strict';

    angular
        .module('cscoe.generate', [])
        .controller('GenerateCtrl', GenerateCtrl);

    /* @ngInject */
    function GenerateCtrl($scope, $q, report, currentSchool) {
        var vm = this;
        vm.title = 'GenerateCtrl';
        console.log(report, currentSchool);
        vm.report = report;
        vm.school = currentSchool;
        var lastYearStartDate = vm.report.reportDates.startDate - 1;
        var lastYearendDate = vm.report.reportDates.endDate - 1;
        vm.report.lastYear = lastYearStartDate.toString() + '/' + lastYearendDate.toString();
        var isChrome = /Chrome/.test(navigator.userAgent) && /Google Inc/.test(navigator.vendor);
        vm.report.totalEnrollment = vm.report.totalEnrollment ? vm.report.totalEnrollment : 0;

        activate();

        function activate() {
            var firefoxChromeFixExpenses = document.getElementById('fix-expenses-margin-chrome-ff');
            var isFirefox = typeof InstallTrigger !== 'undefined';
            var ua = window.navigator.userAgent;
            var msie = ua.indexOf("MSIE ");
            if (!isChrome) {
                var body = document.getElementsByTagName('body');
                var svgContainer = document.getElementsByClassName('js-graph');
                svgContainer[0].className = "col-lg-6 js-graph firefoxOrIE";
                body[0].className = "firefoxOrIE";
                
            } else if(isChrome || isFirefox) {
                firefoxChromeFixExpenses.className = "row margin-top-23-print";
            }

            var chapter = document.getElementsByClassName('js-chapter');
                chapter[0].className = 'chapter';

            if (msie > 0 || !!navigator.userAgent.match(/Trident.*rv\:11\./)) {
                debugger;
                var logo = document.getElementById('logo-for-hide');
                var logo2 = document.getElementById('logo-for-hide2');
                var parentLogoContainer = document.getElementById('logo-container');
                var parentLogoContainer2 = document.getElementById('logo-container2');
                logo.className = 'display-none';
                logo2.className = 'display-none';
                var img = document.createElement('img');
                var img2 = document.createElement('img');
                img.src = '../images/cscoe-logo.svg';
                img.className = "img-responsive logo-image-tag";
                img2.src = '../images/cscoe-logo.svg';
                img2.className = "img-responsive logo-image-tag";
                parentLogoContainer.appendChild(img);
                parentLogoContainer2.appendChild(img2);
            }

            if (isFirefox) {
                firefoxChromeFixExpenses.className = "row margin-top-23-print";
                setFirefoxPrintClass();
            }

            $scope.Math = window.Math;
            initialiseGraph()
                .then(function () {
                    var clip = document.getElementsByTagName('defs');
                    angular.element(clip).remove();
                });

                if (vm.report.enrollment && !!vm.report.enrollment.currentYearEnrollment && !!vm.report.enrollment.lastYearEnrollment) {
                    preKGrowthCalculation(parseInt(vm.report.enrollment.currentYearEnrollment), parseInt(vm.report.enrollment.lastYearEnrollment));
                }else {
                    vm.report.enrollment = vm.report.enrollment || {};
                    vm.report.enrollment.currentYearEnrollment = vm.report.enrollment.currentYearEnrollment || 0;
                    vm.report.enrollment.lastYearEnrollment = vm.report.enrollment.lastYearEnrollment || 0;
                }
        }

        function setFirefoxPrintClass() {
            var elementsWithWhiteText = document.getElementsByClassName('text-white');

            for (var i = 0; i < elementsWithWhiteText.length; i++) {
                elementsWithWhiteText[i].className  += ' firefox-white-text';
            }
        }

        function preKGrowthCalculation(currentYearGrowth, lastYearGrowth) {
            if (currentYearGrowth > 100 || lastYearGrowth > 100) {
                var maxPercentage,
                    minPercentage,
                    difference;
                if (currentYearGrowth > lastYearGrowth) {
                    difference = currentYearGrowth - lastYearGrowth;
                    vm.currentYearGrowthPercentage = 100;
                    vm.lastYearGrowthPercentage = 100 - (difference/currentYearGrowth)*100;
                }else {
                    difference = lastYearGrowth - currentYearGrowth;
                    vm.lastYearGrowthPercentage = 100;
                    vm.currentYearGrowthPercentage = 100 - (difference / lastYearGrowth)*100;
                }
            }else {
                vm.currentYearGrowthPercentage =  vm.report.enrollment.currentYearEnrollment;
                vm.lastYearGrowthPercentage = vm.report.enrollment.lastYearEnrollment;
            }
        }

        function initialiseGraph() {
            var deferred = $q.defer();
            if (vm.report.enrollment) {
                $scope.data = {
                    dataset0: [
                        {x: 1, enrollmentNum: parseInt(vm.report.enrollmentFirstYearValue)  || 0 , labelYear: vm.report.firstYear},
                        {x: 2, enrollmentNum: parseInt(vm.report.enrollmentSecondYearValue) || 0 , labelYear: vm.report.secondYear},
                        {x: 3, enrollmentNum: parseInt(vm.report.enrollmentThirdYearValue)  || 0 , labelYear: vm.report.thirdYear},
                        {x: 4, enrollmentNum: parseInt(vm.report.enrollmentFourthYearValue) || 0 , labelYear: vm.report.fourthYear},
                        {x: 5, enrollmentNum: parseInt(vm.report.enrollmentFifthYearValue)  || 0 , labelYear: vm.report.reportDates.startDate + ' / ' + vm.report.reportDates.endDate}
                    ]
                };
            }else {
                $scope.data = {
                    dataset0: [
                        {x: 1, enrollmentNum:  0 , labelYear: vm.report.firstYear},
                        {x: 2, enrollmentNum:  0 , labelYear: vm.report.secondYear},
                        {x: 3, enrollmentNum:  0 , labelYear: vm.report.thirdYear},
                        {x: 4, enrollmentNum:  0 , labelYear: vm.report.fourthYear},
                        {x: 5, enrollmentNum:  0 , labelYear: vm.report.reportDates.startDate + ' / ' + vm.report.reportDates.endDate}
                    ]
                };
            }

            $scope.options = {
                series: [
                    {
                        axis: "y",
                        dataset: "dataset0",
                        key: "enrollmentNum",
                        color: "#1f77b4",
                        type: [
                            'line',
                            'dot'
                        ]

                    }
                ],
                 axes: {
                    x: {
                        key: "x"
                    }
                }
            };

            deferred.resolve();

            return deferred.promise;

        }

    }
})();
