(function() {
    'use strict';

    angular
        .module('cscoe.register', [])
        .controller('RegisterCtrl', RegisterCtrl);

    /* @ngInject */
    function RegisterCtrl($state, AuthService) {
        var vm = this;
        vm.title = 'RegisterCtrl';
        vm.user = {};

        activate();

        function activate() {
            vm.registerErrMessage = null;
            vm.signUp = signUp;
        }

        function signUp(form) {
            if (form.$valid) {
                AuthService.createAcc(vm.user)
                    .then(function(res) {
                        $state.go('splash');
                    })
                    .catch(function(err) {
                        vm.registerErrMessage = err.message;
                    });
            }
        }

    }

})();
