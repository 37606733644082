(function() {
    'use strict';

    angular
        .module('cscoe.createschool.modal', [])
        .controller('CreateSchoolModalCtrl', CreateSchoolModalCtrl);

    /* @ngInject */
    function CreateSchoolModalCtrl($scope, $timeout, SchoolService, ModalService) {
        var vm = this;

        $scope.$watch('files', function () {
            $scope.attach($scope.files);
        });

        $scope.$watch('file', function () {
            if ($scope.file != null) {
                $scope.files = [$scope.file];
            }
        });

        activate();

        function activate() {
            vm.closeModal = closeModal;
            vm.createSchool = createSchool;
            vm.error = null;
            vm.resetSelectedImage = resetSelectedImage;
        }

        $scope.attach = function (schoolImage) {

            if (schoolImage) {
                vm.selectedImage = schoolImage;
            }
        }

        function resetSelectedImage() {
            vm.selectedImage = null;
            vm.disableSelect = true;
            $timeout(function() {
                 vm.disableSelect = false;
            }, 10);
        }

        function closeModal() {
            ModalService.close();
        }

        function createSchool(form) {
            if (form.$valid) {
                SchoolService.saveSchool(vm.school, vm.selectedImage)
                .then(function(res) {
                    closeModal();
                })
                .catch(function(err) {
                    vm.error = err;
                });
            }
        }


    }
})();
