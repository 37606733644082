(function() {
    'use strict';

    angular
        .module('cscoe.services.errormessage', [])
        .factory('ErrorMessageService', ErrorMessageService);

    /* @ngInject */
    function ErrorMessageService() {

        return {
            getUserError: getUserError
        };

        function getUserError(serverErr) {
            var errorMessage = '';
            switch (serverErr.code) {
                case 'auth/user-not-found':
                    errorMessage = 'Email is either not valid or does not exist.';
                    // errorMessage += serverErr.message;
                    break;
                case 'auth/wrong-password':
                    errorMessage = 'Invalid password.';
                    break;
                case 'auth/argument-error':
                    errorMessage = serverErr.message;
                    break;
                case 'auth/invalid-email':
                    errorMessage = serverErr.message;
                    break;
                case 'auth/too-many-requests':
                    errorMessage = 'Too many requests to server. Try again later.';
                    break;
                case 'auth/invalid-action-code':
                    errorMessage = serverErr.message;
                    break;
                case 'auth/weak-password':
                    errorMessage = serverErr.message;
                    break;
            }

            return errorMessage;
        }

    }
})();
