(function() {
    'use strict';

    angular
        .module('cscoe.services.school', [])
        .factory('SchoolService', SchoolService);

    /* @ngInject */
    function SchoolService($firebaseObject, $firebaseRef, $firebaseArray, $q, $rootScope, ReportsService) {

        var SchoolSockets = [];

        return {
            saveSchool: saveSchool,
            getSchools: getSchools,
            getSchoolByUid: getSchoolByUid,
            getSchoolForCSV: getSchoolForCSV,
            checkIfEmailExist: checkIfEmailExist,
            addReportToSchool: addReportToSchool,
            checkIfSchoolHasReports: checkIfSchoolHasReports,
            verifySchoolUser: verifySchoolUser,
            getSchoolCreationDate: getSchoolCreationDate,
            removeSchoolLogo: removeSchoolLogo,
            deleteSchool: deleteSchool,
            killSockets: killSockets,
            getCSVJSON: getCSVJSON
        };

        function killSockets() {
            for (var i = 0; i < SchoolSockets.length; i++) {
                if (typeof SchoolSockets[i].on === 'function') {
                    SchoolSockets[i].off();
                } else {
                    SchoolSockets[i].$destroy();
                }
            }
        };

        function saveSchool(school, schoolLogo) {
            var deferred = $q.defer();

            var schoolsRef = $firebaseRef.schools;
            if (school.uid) {
                checkIfEmailExist(school.email).then(function(res) {
                    console.log(res);
                    if (!res || school.uid === res.$id) {
                        var schoolRef = schoolsRef.child(school.uid);
                        var updateObject = {
                            email: school.email,
                            name: school.name || '',
                            principalName: school.principalName || ''
                        };

                        schoolRef.update(updateObject)
                            .then(function() {
                                if (schoolLogo) {
                                    var fbStorageRef = firebase.storage().ref('logos/' + school.uid);
                                    fbStorageRef.put(schoolLogo);
                                    console.log('imageAdded');
                                }

                                deferred.resolve('school updated');
                            }, function(err) {

                                console.log(err);
                                deferred.reject(err);
                            });
                    } else {
                        deferred.reject('Email already exist');
                    }
                });
            }else {
                var schoolsArray = $firebaseArray(schoolsRef);

                SchoolSockets.push(schoolsArray); // for closing the sockets on logout

                schoolsArray.$loaded()
                    .then(function() {
                        return checkIfEmailExist(school.email);
                    }, function(err) {

                        console.log(err);
                        deferred.reject(err);
                    })
                    .then(function(exist) {
                        if (!exist) {

                            var newSchool = {
                                name: school.name,
                                email: school.email,
                                principalName: school.principalName || '',
                                created: firebase.database.ServerValue.TIMESTAMP
                            };

                            deferred.resolve('School added');

                            return schoolsArray.$add(newSchool);

                        } else {
                            deferred.reject('Error: User email already exist');
                        }
                    }, function(err) {

                        console.log(err);
                        deferred.reject(err);
                    }).then(function(ref) {
                        console.log(ref.key);
                        if (schoolLogo) {
                            var fbStorageRef = firebase.storage().ref('logos/' + ref.key);
                            fbStorageRef.put(schoolLogo);
                            deferred.resolve('School added');
                        }else {
                            deferred.resolve();
                        }
                    }, function(err) {

                        console.log(err);
                        deferred.reject(err);
                    });
            }

            return deferred.promise;
        }

        function removeSchoolLogo(schoolUid) {
            var deferred = $q.defer();

            var fbStorageRef = firebase.storage().ref('logos/' + schoolUid);

            checkIfSchoolHasLogo(schoolUid)
                .then(function(url) {
                    fbStorageRef.delete();
                    deferred.resolve('Logo removed from storage');
                }).catch(function(err) {
                    deferred.reject(err.code);
                });

            return deferred.promise;
        }

        function checkIfSchoolHasLogo(schoolUid) {
            var deferred = $q.defer();
            var fbStorageRef = firebase.storage().ref('logos/' + schoolUid);

            fbStorageRef.getDownloadURL()
                .then(function(res) {
                    deferred.resolve(res);
                }).catch(function(err) {
                deferred.reject(err);
            });

            return deferred.promise;
        }

        function checkIfEmailExist(email) {
            var deferred = $q.defer();
            //if it doesn't go add it to database
            var schoolsRef = $firebaseRef.schools,
                schoolList = $firebaseArray(schoolsRef);

            SchoolSockets.push(schoolList); // for closing the sockets on logout

            schoolList.$loaded()
                .then(function(res) {
                    var emailExists = false;
                    for (var i = 0; i < res.length; i++) {
                        emailExists = res[i].email === email;
                        if (emailExists) {
                            deferred.resolve(res[i]);
                            break;
                        }
                    }

                    deferred.resolve(null);
                })
                .catch(function(err) {
                    console.log(err);
                    deferred.reject(err);
                });

            return deferred.promise;
        }

        function getSchools() {
            var schoolsRef = $firebaseRef.schools;
            var schoolsList = $firebaseArray(schoolsRef);
            SchoolSockets.push(schoolsList); // for closing the sockets on logout
            return schoolsList.$loaded();
        }

        function flatten(obj, path, result) {
            var key, val, _path;
            path = path || [];
            result = result || {};
            for (key in obj) {
                if (key.indexOf('$') === -1) {
                    val = obj[key];
                    _path = path.concat([key]);
                    if (val instanceof Object) {
                        flatten(val, _path, result);
                    } else {
                        result[_path.join('.')] = val;
                    }
                }
            }

            return result;
        };

        function getCSVJSON() {
            return ReportsService.getReports()
                    .then(function(reports) { // accumulating reports with schools
                        var promises = [];
                        reports.forEach(function(report) {
                            promises.push(
                                getSchoolForCSV(report.schoolId)
                                .then(function(schoolInfo) {
                                    // there is a possibility of malformed old reports with
                                    // unexisting schools
                                    if (!!schoolInfo) {
                                        if(schoolInfo.reports) {
                                            delete schoolInfo.reports;
                                        }

                                        delete report.schoolId;

                                        schoolInfo.created = new Date(schoolInfo.created).toUTCString();
                                        report.updated = new Date(report.updated).toUTCString();

                                        report.school = schoolInfo;
                                        return $q.when(report);
                                    } else {
                                        return $q.resolve();
                                    }
                                })
                            );
                        });

                        return $q.all(promises);
                    })
                    .then(function(populatedReports) { // flattening the schools with reports
                        var reports = [];
                        populatedReports.forEach(function(data) {
                            if(data) {
                                var result = flatten(data);
                                if(!result['financials.hasOpenSeats']) {
                                    result['financials.hasOpenSeats'] = "No open seats";
                                }else {
                                    result['financials.hasOpenSeats'] = "Open seats";
                                }
                                reports.push(result);
                            }
                        });

                        console.log(reports);
                        return $q.when(reports);
                    });
        }

        function getSchoolForCSV(uid) {
            var def = $q.defer();
            var schoolRef = $firebaseRef.schools.child(uid);
            schoolRef.on('value', function(snap) {
                def.resolve(snap.val());
            });

            return def.promise;
        }

        function getSchoolCreationDate(schoolId) {
            var def = $q.defer();
            var schoolRef = $firebaseRef.schools.child(schoolId);
            var fbObject = $firebaseObject(schoolRef);
            fbObject.$loaded()
                .then(function (res) {
                    def.resolve(res.created);
                })
                .catch(function (err) {
                    def.reject(err);
                });

            return def.promise;
        }

        function verifySchoolUser(schoolId, userEmail) {
            var def = $q.defer();

            var schoolsRef = $firebaseRef.schools,
                schoolRef = schoolsRef.child(schoolId);

            schoolRef.on('value', function(snap) {
                var school = snap.val();
                def.resolve(school.email === userEmail);

            }, function(err) {

                console.log(err);
                def.reject(err);
            });

            return def.promise;
        }

        function getSchoolByUid(uid) {
            var deferred = $q.defer();
            var schoolsRef = $firebaseRef.schools.child(uid);
            var schoolLogoUrl;
            firebase.storage().ref('logos/' + uid).getDownloadURL()
                .then(function(url) {
                    schoolLogoUrl = url;

                    var fbObject = $firebaseObject(schoolsRef);
                    fbObject.$loaded()
                        .then(function (res) {
                            var currentSchool = {
                                name: res.name,
                                email: res.email,
                                principalName: res.principalName,
                                schoolLogo: schoolLogoUrl,
                                uid: uid
                            };
                            deferred.resolve(currentSchool);
                        }).catch(function (err) {
                            deferred.reject(err);
                        });

                })
                .catch(function(error) {
                    var fbObject = $firebaseObject(schoolsRef);
                    fbObject.$loaded()
                        .then(function (res) {
                            var currentSchool = {
                                name: res.name,
                                email: res.email,
                                principalName: res.principalName,
                                schoolLogo: undefined,
                                uid: uid
                            };
                            deferred.resolve(currentSchool);
                        }).catch(function (err) {
                            deferred.reject(err);
                        });
                    // schoolsRef.on('value', function(snap) {
                    //     var school =  snap.val();
                    //     var currentSchool = {
                    //         name: school.name,
                    //         email: school.email,
                    //         principalName: school.principalName,
                    //         schoolLogo: undefined,
                    //         uid: uid
                    //     };

                        SchoolSockets.push(schoolsRef);
                        // We resolve in both cases because if school
                        // has no image in storage we cant preview school in dashboard
                });

            return deferred.promise;
        }

        function checkIfSchoolHasReports(schoolUid) {
            var deferred = $q.defer();
            var schoolsRef = $firebaseRef.schools;

            var schoolRef = schoolsRef.child(schoolUid);

            var obj = $firebaseObject(schoolRef);
            obj.$loaded()
                .then(function(res){
                    var currentSchool = res;

                    SchoolSockets.push(schoolRef);

                    if (!currentSchool.reports) {
                        deferred.resolve([]);
                    } else {
                        deferred.resolve(currentSchool.reports);
                    }
                }).catch(function (err) {
                    console.log(err);
                    deferred.reject(err);
                });

            return deferred.promise;
        }

        function deleteSchool(schoolUid) {
            var deferred = $q.defer();

            checkIfSchoolHasReports(schoolUid)
                .then(function(schoolReportsUid) {
                    return deleteReports(schoolReportsUid);
                })
                .then(function() {
                    return removeSchoolLogo(schoolUid);
                })
                .catch(function(err) {
                    var schoolsRef = $firebaseRef.schools;
                    var schoolToDelete = schoolsRef.child(schoolUid);
                    schoolToDelete.remove();
                    deferred.resolve('School Deleted');
                })
                .then(function() {
                    var schoolsRef = $firebaseRef.schools;
                    var schoolToDelete = schoolsRef.child(schoolUid);
                    schoolToDelete.remove();
                    deferred.resolve('School Deleted');
                });

            return deferred.promise;
        }

        function deleteReports(schoolReportsUid) {
            var deferred = $q.defer();
            var deletedReportsPromises = [];

            for (var reportKey in schoolReportsUid) {
                deletedReportsPromises.push(ReportsService.deleteReport(schoolReportsUid[reportKey]));
            }

            return $q.all(deletedReportsPromises);
        }

        function addReportToSchool(schoolId, report) {
            var deferred = $q.defer();
            var schoolsRef = $firebaseRef.schools;
            // var schoolsArray = $firebaseArray(schoolsRef);

            var reportsRef = schoolsRef.child(schoolId).child('reports');
            var reportsArray = $firebaseArray(reportsRef);

            SchoolSockets.push(reportsArray); // for closing the sockets on logout

            reportsArray.$loaded().then(function() {
                reportsArray.$add(report.$id);
                deferred.resolve(report);
            })
            .catch(function(err) {
                deferred.reject(err);
            });

            return deferred.promise;
        }

    }
})();
