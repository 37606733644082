(function() {
    'use strict';

    angular
        .module('cscoe.auth', [])
        .controller('AuthCtrl', AuthCtrl);

    /* @ngInject */
    function AuthCtrl($location, $state) {
        var vm = this;
        var parsedUrl = null;

        activate();

        ////////////////

        function activate() {
            parsedUrl = $location.$$search;

            switch (parsedUrl.mode) {
                case 'resetPassword':
                    $state.go('passwordreset', {
                        oobCode: parsedUrl.oobCode
                    });
                    break;

                case 'verifyEmail':
                    $state.go('verifyemail', {
                        oobCode: parsedUrl.oobCode
                    });
                    break;
            }
        }
    }
})();
