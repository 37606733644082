(function() {
    'use strict';

    angular
        .module('cscoe')
        .filter('percentage', ['$filter', percentage]);

    // This filter makes the assumption that the input
    // will be in decimal form (i.e. 17% is 0.17).
    function percentage($filter) {
        return function(input, decimals) {
            return $filter('number')(input * 100, decimals) + '%';
        };
    }

})();
