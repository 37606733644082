(function() {
    'use strict';

    angular
        .module('cscoe')
        .run(setEvents)
        .config(setRoutes)
        .config(function($firebaseRefProvider, AuthServiceProvider, firebaseConnectionApi) {

            var app = firebase.initializeApp(firebaseConnectionApi);

            AuthServiceProvider.configure({
                nativeAuthObject: app.auth(),
                firebaseConfig: firebaseConnectionApi,
                firebase: firebase
            });

            $firebaseRefProvider.registerUrl({
                default: firebaseConnectionApi.databaseURL,
                users: firebaseConnectionApi.databaseURL + '/users',
                schools: firebaseConnectionApi.databaseURL + '/schools',
                reports: firebaseConnectionApi.databaseURL + '/reports',
                timestamp: firebaseConnectionApi.databaseURL + '/timestamp'
            });

        });

    /* @ngInject */
    function setRoutes($stateProvider, $urlRouterProvider, AuthServiceProvider) {

        console.log('setRoutes');
        $stateProvider
        .state('register', {
            cache:false,
            url: '/register',
            controller: 'RegisterCtrl',
            controllerAs: 'vm',
            templateUrl: 'app/register/register.view.html',
            data: {
                noAuth: true
            }
        })
        .state('login', {
            cache:false,
            url: '/login',
            controller: 'LoginCtrl',
            controllerAs: 'vm',
            templateUrl: 'app/login/login.view.html',
            data: {
                noAuth: true
            }
            // resolve:{
            //     currentAuth: function(AuthService) {
            //         return AuthService.waitForSignIn();
            //     }
            // }
        })
        .state('dashboard', {
            cache:false,
            url: '/dashboard/:schoolId?reportId',
            controller: 'DashboardCtrl',
            reloadOnSearch:false,
            controllerAs: 'vm',
            templateUrl: 'app/dashboard/dashboard.view.html',
            resolve: {
                currentSchool: function(SchoolService, $stateParams) {
                    return SchoolService.getSchoolByUid($stateParams.schoolId);
                },

                currentAuth: function(AuthService) {
                    return AuthService.waitForSignIn();
                }
            },
            data: {
                roles: ['user', 'admin']
            }
        })
        .state('admin', {
            cache:false,
            url: '/admin',
            controller: 'AdminCtrl',
            controllerAs: 'vm',
            templateUrl: 'app/admin/admin.view.html',
            resolve: {
                schools : ['SchoolService', function(SchoolService) {
                    return SchoolService.getSchools();
                }],

                currentAuth: function(AuthService) {
                    return AuthService.waitForSignIn();
                }
            },
            data: {
                roles: ['admin']
            }
        })
        .state('generate', {
            cache:false,
            url: '/generate/:schoolId/:reportId',
            controller: 'GenerateCtrl',
            controllerAs: 'vm',
            templateUrl: 'app/generate/generate.view.html',
            resolve: {
                report : function(ReportsService, $stateParams) {
                    return ReportsService.getReportFBObject($stateParams.reportId, $stateParams.schoolId);
                },

                currentSchool: function(SchoolService, $stateParams) {
                    return SchoolService.getSchoolByUid($stateParams.schoolId);
                },

                currentAuth: function(AuthService) {
                    return AuthService.waitForSignIn();
                }
            },
            data: {
                roles: ['user', 'admin']
            }
        })
        .state('splash', {
            cache:false,
            url: '/splash',
            controller: 'SplashCtrl',
            controllerAs: 'vm',
            templateUrl: 'app/splash/splash.view.html',
            params: {
                splashInformation: null,
                splashHeader: null
            },
            data: {
                noAuth: false,
                roles: ['user']
            }
        })
        .state('auth', {
            cache:false,
            url: '/auth/action?mode&oobCode',
            controller: 'AuthCtrl',
            controllerAs: 'vm',
            templateUrl: 'app/auth/auth.view.html',
            data: {
                noAuth: true
            }
        })
        .state('verifyemail', {
            cache:false,
            url: '/verifyemail',
            controller: 'VerifyEmailCtrl',
            controllerAs: 'vm',
            templateUrl: 'app/verifyemail/verifyemail.view.html',
            params: {
                oobCode: null
            },
            data: {
                noAuth: true
            }
        })
        .state('passwordreset', {
            cache:false,
            url: '/passwordreset',
            controller: 'ResetPassCtrl',
            controllerAs: 'vm',
            params: {
                oobCode: null
            },
            templateUrl: 'app/passwordreset/passwordreset.view.html',
            data: {
                noAuth: true
            }
        })
        .state('noauthsplash', {
            cache:false,
            url: '/noauthsplash',
            templateUrl: 'app/noauthsplash/noauthsplash.view.html',
            data: {
                noAuth: true
            }
        });

        //$urlRouterProvider.otherwise('/login');
        $urlRouterProvider.otherwise(function($injector) {
            var $state = $injector.get('$state');
            $state.go('login');
        });
    }

    /* @ngInject */
    function setEvents($rootScope, $state, AuthService, SchoolService) {
        console.log('setEvents');
        $rootScope.$on('$stateChangeError', function(event, toState, toParams, fromState, fromParams, error) {
            console.log(error);
            if (toParams.schoolId) {
                proceedToState({name: 'dashboard'}, {
                    schoolId: toParams.schoolId
                }, fromState, fromParams, true);
            } else {
                proceedToState({name: 'splash'}, {
                    splashInformation: 'User does not have permission to view this page.',
                    splashHeader: 'User cannot view this school'
                }, fromState, fromParams, true);
            }

        });

        $rootScope.$on('$stateChangeStart', function(event, toState, toParams, fromState, fromParams, error) {
            console.log(error);
            event.preventDefault();
            $state.transition = true;
            AuthService.waitForSignIn().then(function(authUser) {
                console.log(authUser);
                if (toState.data && toState.data.noAuth) {
                    console.log('state doesnt require auth');
                    if (toState.name === 'auth' ||
                        toState.name === 'verifyemail' ||
                        toState.name === 'passwordreset' ||
                        toState.name === 'noauthsplash' ||
                        toState.name === 'login') {
                        proceedToState(toState, toParams, fromState, fromParams);
                    } else if (authUser) {
                        console.log('user is logged in');
                        if (authUser.emailVerified)  {
                            AuthService.getUserRoles()
                                .then(function(userRoles) {
                                    //console.log(roles);
                                    if (userRoles.indexOf('admin') !== -1) {
                                        proceedToState({name: 'admin'}, toParams, fromState, fromParams);
                                    } else if (userRoles.indexOf('user') !== -1) {
                                        SchoolService.checkIfEmailExist(authUser.email)
                                        .then(function(res) {
                                            if (res) {
                                                proceedToState({name: 'dashboard'}, {schoolId: res.$id}, fromState, fromParams);
                                            } else {
                                                proceedToState({name: 'splash'}, {
                                                            splashInformation: 'Sorry! Your e-mail isn\'t associated with a school. Contact CSCOE for more information.',
                                                            splashHeader: 'User has no school'
                                                        }, fromState, fromParams, true);
                                            }
                                        });
                                    } else {
                                        console.log('I dont have to be here');
                                        proceedToState({name: 'splash'}, {
                                            splashInformation: 'User does not have permission to view this page.',
                                            splashHeader: 'User cannot view this school'
                                        }, fromState, fromParams, true);
                                    }
                                });
                        } else {
                            proceedToState({name: 'splash'}, {
                                splashInformation: 'You must verify your email.',
                                splashHeader: 'User not verified'
                            }, fromState, fromParams, true);
                        }
                    } else {
                        console.log('user is not logged in');
                        proceedToState(toState, toParams, fromState, fromParams);
                    }

                } else {
                    console.log('state requires auth');
                    if (authUser) {
                        console.log('user is logged in');
                        if (authUser.emailVerified) {
                            AuthService.getUserRoles()
                                .then(function(userRoles) {
                                    var roles = userRoles.filter(function(role) {
                                        return toState.data.roles.indexOf(role) !== -1;
                                    });

                                    if (roles.indexOf('admin') !== -1) {
                                        proceedToState(toState, toParams, fromState, fromParams);
                                    } else if (roles.indexOf('user') !== -1) {
                                        //if state is dashboard or generate
                                        //check if this user is assigned to this school

                                        console.log(toState.name);
                                        SchoolService.checkIfEmailExist(authUser.email)
                                        .then(function(res) {
                                            if (res) {
                                                console.log(toParams.schoolId);
                                                if (res.$id && !toParams.schoolId) {
                                                    proceedToState({name: 'dashboard'}, {schoolId: res.$id}, fromState, fromParams);
                                                } else if (res.$id == toParams.schoolId) {
                                                    proceedToState(toState, toParams, fromState, fromParams);
                                                } else {
                                                    proceedToState({name: 'splash'}, {
                                                        splashInformation: 'User does not have permission to view this page.',
                                                        splashHeader: 'User cannot view this school'
                                                    }, fromState, fromParams, true);
                                                }

                                            } else {
                                                proceedToState({name: 'splash'}, {
                                                    splashInformation: 'Sorry! Your e-mail isn\'t associated with a school. Contact CSCOE for more information.',
                                                    splashHeader: 'User has no school'
                                                }, fromState, fromParams, true);
                                            }
                                        });
                                    } else {
                                        console.log('I dont have to be here');
                                        proceedToState({name: 'splash'}, {
                                            splashInformation: 'User does not have permission to view this page.',
                                            splashHeader: 'User cannot view this school'
                                        }, fromState, fromParams, true);
                                    }
                                });
                        } else {
                            proceedToState({name: 'splash'}, {
                                splashInformation: 'You must verify your email.',
                                splashHeader: 'User not verified'
                            }, fromState, fromParams, true);
                        }

                    } else {
                        console.log('user is not logged in');
                        proceedToState({name: 'login'}, toParams, fromState, fromParams);
                    }

                }
            });

        });

        function proceedToState(toState, toParams, fromState, fromParams, replace) {
            var stateOptions = {
                notify: false
            };
            if (replace) {
                stateOptions.location = 'replace';
            }

            $state.go(toState.name, toParams, stateOptions).then(function() {
                $rootScope.$broadcast('$stateChangeSuccess', toState, toParams, fromState, fromParams);
            });
        }
        /*$rootScope.$on('$stateChangeError', function(event, toState, toParams, fromState, fromParams, error) {
          console.log('error with states', fromState, fromParams, toState, toParams);
          //event.preventDefault();
          $state.go('login');
        });*/
    }

})();
