(function() {
    'use strict';
    angular.element(document).ready(function() {
        angular.bootstrap(document, ['cscoe'], {
            strictDi: true
        });
    });

    var cscoeDependencies = [
        'cscoe.firebase.config',

        'ui.router',
        'ngAnimate',
        'ngMessages',
        'firebase',
        'ui.bootstrap',
        'ngFileUpload',
        'n3-line-chart',
        'angularMoment',
        'filereader',
        'ngSanitize',
        'ngCsv',
        'ui.utils.masks',

        'cscoe.restrict.input',

        'cscoe.directive.compareTo',

        'cscoe.services.auth',
        'cscoe.services.school',
        'cscoe.services.errormessage',
        'cscoe.services.reports',

        'cscoe.register',
        'cscoe.login',
        'cscoe.admin',
        'cscoe.dashboard',
        'cscoe.generate',
        'cscoe.settings.modal',
        'cscoe.editschool.modal',
        'cscoe.createschool.modal',
        'cscoe.auth',
        'cscoe.verifyemail',
        'cscoe.passwordreset',
        'cscoe.splash'

    ];
    /* Check if there are minified templates (done in the html2js gulp task) */
    try {
        angular.module('served.templates');
        cscoeDependencies.push('served.templates');
    } catch (e) {
        console.warn(e,'No cached templates available test');
    }

    angular.module('cscoe', cscoeDependencies)
      .run(setConfig);

    /* @ngInject */
    function setConfig() {
        console.log('setConfig');
    }

})();
