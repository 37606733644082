angular.module("cscoe.firebase.config", [])

.constant("firebaseConnectionApi", {
	"apiKey": "AIzaSyCxurnbmNLm13MIZJGZr5hkqgbrWnPkcrY",
	"authDomain": "cscoe-2531f.firebaseapp.com",
	"databaseURL": "https://cscoe-2531f.firebaseio.com",
	"storageBucket": "cscoe-2531f.appspot.com",
	"messagingSenderId": "99711605701"
})

;