(function() {
    'use strict';

    angular
        .module('cscoe.passwordreset', [])
        .controller('ResetPassCtrl', ResetPassCtrl);

    /* @ngInject */
    function ResetPassCtrl($scope, $location, $state, $timeout, AuthService, SchoolService, ErrorMessageService) {
        var vm = this;
        vm.title = 'Controller';
        var parsedUrl = null;

        activate();

        vm.resetPassword = resetPassword;

        ////////////////

        function activate() {
            $scope.resetPasswordReady = false;
            vm.user = {
                email: '',
                password: '',
                confirmed_password: ''
            };

            AuthService.verifyPasswordResetCode($state.params.oobCode)
                .then(function(email) {
                    vm.user.email = email;
                    $scope.resetPasswordReady = true;
                    $scope.$apply();
                })
                .catch(function(error) {
                    // Error occurred during confirmation. The code might have expired or the
                    // password is too weak.
                    console.log(error);

                    var errorMsg = ErrorMessageService.getUserError(error);

                    $state.go('splash', {
                        splashInformation: errorMsg,
                        splashHeader: 'Error while resetting password'
                    });
                });
        }

        function resetPassword(form) {
            if (form.$valid) {
                AuthService.confirmPasswordReset($state.params.oobCode, vm.user.password)
                    .then(function() {
                        $state.go('login');
                    })
                    .catch(function(err) {
                        console.log(err);
                        vm.passErrMessage = err;
                    });
            }
        }
    }
})();
