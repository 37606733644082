(function () {
    'use strict';

    angular
        .module ('cscoe.restrict.input', [])
        .directive ('restrictInput', restrictInput);


    function restrictInput() {
        // Usage:
        //     <directive></directive>
        // Creates:
        //
        var directive = {
            link: link,
            restrict: 'A'
        };
        return directive;

        function link(scope, element, attrs) {
            var targets = element;
            var pattern = attrs.restrictPattern || /^((NA)|\d{1,2})$/; // some default pattern

            var restrictHandler = function(){
                var val = element.val();

                // var newVal = val.replace(pattern);

                if (!val.match(pattern)) { // To prevent selection and keyboard navigation issues
                    element.val("NA");
                    element.triggerHandler('change');
                }
            };
            targets.on('paste', restrictHandler);
            targets.on('change', restrictHandler);
        }
    }

})();
