(function() {
    'use strict';

    angular
        .module('cscoe')
        .factory('ModalService', ModalService);

    /* @ngInject */
    function ModalService($uibModal, $q) {
        var modalInstance;
        var opened = false;

        return {
            open: open,
            close: close
        };

        function open(templateUrl, ctrl, schoolId, report) {

            if (opened) return;

            if (schoolId) {
                modalInstance = $uibModal.open({
                    animation: true,
                    templateUrl: templateUrl,
                    controller: ctrl,
                    controllerAs: 'vm',
                    resolve: {
                        currentSchool: function(SchoolService) {
                            return SchoolService.getSchoolByUid(schoolId);
                        },

                        report: report,
                        schoolId: {
                            val: schoolId
                        }
                    }
                });

                opened = true;

                modalInstance.result.then(function(){
                    //Get triggers when modal is close
                    opened = false;
                }, function(){
                   //gets triggers when modal is dismissed.
                   opened = false;
                });

            } else {
                modalInstance = $uibModal.open({
                    animation: true,
                    templateUrl: templateUrl,
                    controller: ctrl,
                    controllerAs: 'vm',
                });

                opened = true;

                modalInstance.result.then(function(){
                    //Get triggers when modal is close
                    opened = false;
                 }, function(){
                    //gets triggers when modal is dismissed.
                    opened = false;
                 });
            }
        }

        function close() {
            modalInstance.close();
        }

    }
})();
