(function() {
    'use strict';

    angular
        .module('cscoe.admin', [])
        .controller('AdminCtrl', AdminCtrl);

    /* @ngInject */
    function AdminCtrl($state, AuthService, SchoolService, ReportsService, ModalService, schools) {
        var vm = this;

        activate();

        vm.setSortTypeName = setSortTypeName;
        vm.setSortTypePrincipal = setSortTypePrincipal;
        vm.setSortTypeEmail = setSortTypeEmail;
        vm.toggleReverse = toggleReverse;
        vm.showCarretDown = showCarretDown;
        vm.showCarretUp = showCarretUp;
        vm.deleteUser = deleteUser;
        vm.openModal = openModal;
        vm.logout = logout;
        vm.getSchoolsCSV = getSchoolsCSV;
        vm.getCSVHeader = getCSVHeader;
        vm.getCSVOrder = getCSVOrder;
        vm.getFilename = getFilename;

        function activate() {
            vm.schools = schools;
            vm.sortTable = {
                sortType: 'name',
                sortReverse: false,
                searchQuery: ''
            };
            vm.createAcc = createAcc;
        }

        function openModal(templateUrl, ctrl, schoolId) {
            ModalService.open(templateUrl, ctrl, schoolId);
        }

        function createAcc(user) {
            AuthService.createAcc(user)
                .then(function(res) {
                    console.log(res);
                });
        }

        function logout(e) {
            e.preventDefault();
            SchoolService.killSockets();
            ReportsService.killSockets();
            AuthService.killSockets();
            AuthService.logout().then(function() {
                $state.go('login');
            });
        }

        function setSortTypeName() {
            vm.sortTable.sortType = 'name';
        }

        function setSortTypePrincipal() {
            vm.sortTable.sortType = 'principalName';
        }

        function setSortTypeEmail() {
            vm.sortTable.sortType = 'email';
        }

        function toggleReverse() {
            vm.sortTable.sortReverse = !vm.sortTable.sortReverse;
        }

        function showCarretDown(type) {
            return vm.sortTable.sortType === type && !vm.sortTable.sortReverse;
        }

        function showCarretUp(type) {
            return vm.sortTable.sortType === type && vm.sortTable.sortReverse;
        }

        function deleteUser(e, user) {
            e.preventDefault();
        }

        function getSchoolsCSV() {
            return SchoolService.getCSVJSON();
        }

        function getFilename() {
            return new Date(Date.now()).toString() + ' all-reports.csv';
        }

        function getCSVHeader() {
            return [
                'school_name',
                'school_principalName',
                'school_created',
                'school_email',
                'updated',
                'timespan',
                'reportDates_endDate',
                'reportDates_startDate',
                'firstYear',
                'secondYear',
                'thirdYear',
                'fourthYear',
                'fifthYear',
                'satisfaction_goal',
                'satisfaction_retention',
                'satisfaction_status',
                'academics_mathematics_myschool',
                'academics_mathematics_comparison',
                'academics_reading_myschool',
                'academics_reading_comparison',
                'academics_status',
                'enrollment_goal',
                'enrollment_status',
                'enrollment_currentYearEnrollment',
                'enrollment_lastYearEnrollment',
                'enrollment_totalEnrollment',
                'enrollmentFirstYearValue',
                'enrollmentSecondYearValue',
                'enrollmentThirdYearValue',
                'enrollmentFourthYearValue',
                'enrollmentFifthYearValue',
                'financials_costPerStudent',
                'financials_income_fundraising',
                'financials_income_parishsupport',
                'financials_income_tuition',
                'financials_status',
                'open_seats',
                'Pre-K',
                'kindergarten',
                'first_grade',
                'second_grade',
                'third_grade',
                'fourth_grade',
                'fifth_grade',
                'sixth_grade',
                'seventh_grade',
                'eighth_grade',
                'formation_goal',
                'formation_hours',
                'formation_status'
            ];
        }

        function getCSVOrder() {
            return [
                'school.name',
                'school.principalName',
                'school.created',
                'school.email',
                'updated',
                'timespan',
                'reportDates.endDate',
                'reportDates.startDate',
                'firstYear',
                'secondYear',
                'thirdYear',
                'fourthYear',
                'timespan',
                'satisfaction.goal',
                'satisfaction.retention',
                'satisfaction.status',
                'academics.mathematics.myschool',
                'academics.mathematics.comparison',
                'academics.reading.myschool',
                'academics.reading.comparison',
                'academics.status',
                'enrollment.goal',
                'enrollment.status',
                'enrollment.currentYearEnrollment',
                'enrollment.lastYearEnrollment',
                'totalEnrollment',
                'enrollmentFirstYearValue',
                'enrollmentSecondYearValue',
                'enrollmentThirdYearValue',
                'enrollmentFourthYearValue',
                'enrollmentFifthYearValue',
                'financials.costPerStudent',
                'financials.income.fundraising',
                'financials.income.parishsupport',
                'financials.income.tuition',
                'financials.status',
                'financials.hasOpenSeats',
                'financials.openSeats.preK',
                'financials.openSeats.kindergarten',
                'financials.openSeats.firstGrade',
                'financials.openSeats.secondGrade',
                'financials.openSeats.thirdGrade',
                'financials.openSeats.fourthGrade',
                'financials.openSeats.fifthGrade',
                'financials.openSeats.sixstGrade',
                'financials.openSeats.seventhGrade',
                'financials.openSeats.eightGrade',
                'formation.goal',
                'formation.hours',
                'formation.status'
            ];
        }
    }
})();
