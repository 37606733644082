(function() {
    'use strict';

    angular
        .module('cscoe.dashboard', [])
        .controller('DashboardCtrl', DashboardCtrl);

    /* @ngInject */
    function DashboardCtrl($rootScope, $scope, $state, $q, moment, AuthService, ModalService, ReportsService, SchoolService, currentSchool) {
        var vm = this;
        activate();

        $rootScope.$on('NewYearCreated', function() {
            vm.firebaseReports = [];
            checkIfSchoolHasReports(currentSchool.uid);
        });

        function activate() {
            $scope.Math = window.Math;
            vm.logout = logout;
            vm.sendPasswordResetEmail = sendPasswordResetEmail;
            vm.report = {};
            vm.firebaseReports = [];
            vm.showResetPass = !$state.current.data.isAdmin;
            vm.runReport = runReport;
            vm.currentSchool = currentSchool;
            vm.updateReport = updateReport;
            vm.viewReport = viewReport;
            vm.openModal = openModal;
            vm.totalEnrollment = totalEnrollment;
            vm.openSettings = openSettings;

            isAdmin();

            $scope.status = {
                isCustomHeaderOpen: false,
                isFirstOpen: true,
                isFirstDisabled: false,
                yearSelectIsOpen: false,
                academics: true,
                enrollment: true,
                satisfaction: true,
                formation: true,
                financials : true

            };

            checkIfSchoolHasReports(currentSchool.uid);
        }

        function openSettings(e) {
            e.preventDefault();
            e.stopPropagation();
            ModalService.open('app/dashboard/settings.modal.html', 'SettingsModalCtrl', currentSchool.uid, vm.report);
        }

        function isAdmin() {
            AuthService.isAdmin()
                .then(function(res) {
                    vm.isAdmin = res;
                });
        }

        function openModal() {
            ModalService.open('app/dashboard/newyear.confirmation.modal.html', 'NewYearConfirmationModalCtrl', currentSchool.uid, vm.firebaseReports[vm.firebaseReports.length - 1]);
        }

        function determineSchoolAcademicYearToCreate(creationDate) {
            var creationDateParsed = moment(creationDate);
            var schoolYearStart = moment([creationDateParsed.year(), 8]); // 1st of September
            if (creationDateParsed.isBefore(schoolYearStart)) {
                return {
                    startDate: creationDateParsed.year() - 1,
                    endDate: creationDateParsed.year()
                };
            } else {
                return {
                    startDate: creationDateParsed.year(),
                    endDate: creationDateParsed.year() + 1
                };
            }
        }

        function runReport(reportUid) {
            $state.go('generate', {schoolId: currentSchool.uid ,reportId: reportUid});
        }

        function updateReport() {
            if (isNaN(vm.report.totalEnrollment) || !vm.report.totalEnrollment) {
                vm.report.totalEnrollment = 0;
            }

            ReportsService.updateReport(vm.report)
                .then(function() {
                });
        }

        function totalEnrollment(lastYear, currentYear) {
            lastYear = parseInt(lastYear);
            currentYear = parseInt(currentYear);

            var fraction = (currentYear - lastYear) / lastYear;
            vm.report.totalEnrollment = fraction * 100;

            updateReport();
            return vm.report.totalEnrollment;
        }

        function checkIfSchoolHasReports(schoolUid) {
            SchoolService.checkIfSchoolHasReports(schoolUid)
                .then(function(reports) {
                    var reportsIds = [];
                    for (var key in reports) {
                        reportsIds.push(reports[key]);
                    }

                    return ReportsService.getAllReports(reportsIds, schoolUid);
                })
                .then(function(reportsForSchool) {
                    ReportsService.saveTimeStamp()
                        .then(function (date) {
                            var foundCurrentDate = determineSchoolAcademicYearToCreate(date); // new Date('Thu Nov 01 2025 17:09:01 GMT+0200')
                            var lastReportDate = reportsForSchool[reportsForSchool.length-1] ? reportsForSchool[reportsForSchool.length-1].valueOf().reportDates : foundCurrentDate;

                            var datesAreEqual = angular.equals(foundCurrentDate, lastReportDate);

                            if (datesAreEqual) {
                                if ($state.params.reportId) { // if any academic year different than current has been selected we load it
                                    for (var i = 0; i < reportsForSchool.length; i++) {
                                        vm.firebaseReports.push(reportsForSchool[i].valueOf());
                                        if(reportsForSchool[i].$id === $state.params.reportId){
                                            vm.report = reportsForSchool[i].valueOf();
                                        }
                                    }
                                } else { // if we load dashboard for first time we load current year report
                                    for (var index in reportsForSchool) {
                                        vm.firebaseReports.push(reportsForSchool[index].valueOf())
                                        if (angular.equals(foundCurrentDate, reportsForSchool[index].valueOf().reportDates)) {
                                            vm.report = reportsForSchool[index].valueOf();
                                            $state.go('dashboard', {'schoolId':vm.report.schoolId , 'reportId' :vm.report.$id });
                                        }
                                    }
                                }
                            } else {
                                if ($state.params.reportId) { // if any academic year different than current has been selected we load it
                                    for (var i = 0; i < reportsForSchool.length; i++) {
                                        vm.firebaseReports.push(reportsForSchool[i].valueOf());
                                        if(reportsForSchool[i].$id === $state.params.reportId){
                                            vm.report = reportsForSchool[i].valueOf();
                                        }
                                    }
                                    if(foundCurrentDate.startDate > lastReportDate.startDate){ // IF LAST REPORT DATE DOESNT MATCH FOUND CURRENT DATE ---> START NEW YEAR
                                        startNewSchoolYear(currentSchool.uid, foundCurrentDate, lastReportDate);
                                    }
                                }else{//ELSE WE LOAD DASHBOARD FOR FIRST TIME AFTER LOGIN
                                    for (var index in reportsForSchool) {
                                        vm.firebaseReports.push(reportsForSchool[index].valueOf())
                                        if (angular.equals(foundCurrentDate, reportsForSchool[index].valueOf().reportDates)) {
                                            vm.report = reportsForSchool[index].valueOf();
                                            $state.go('dashboard', {'schoolId':vm.report.schoolId , 'reportId' :vm.report.$id });
                                        }
                                    }
                                    if(foundCurrentDate.startDate > lastReportDate.startDate){ // IF LAST REPORT DATE DOESNT MATCH FOUND CURRENT DATE ---> START NEW YEAR
                                        startNewSchoolYear(currentSchool.uid, foundCurrentDate, lastReportDate);
                                    }
                                }
                            }

                            if (vm.firebaseReports.length === 0) {
                                createFirstReport(currentSchool.uid, foundCurrentDate);
                            }
                        })

                });
        }

        function fillMissingDates(schoolId, foundCurrentDate, firstAvailableDate, hasFirstReportDateCreated) {
            var i;
            var differenceBetweenStartYears = foundCurrentDate.startDate - firstAvailableDate.startDate;
            var missingDatesArray = [];

            if (hasFirstReportDateCreated) {
                i = 1;
            } else {
                i = 0;
            }
            for (i; i <= differenceBetweenStartYears; i++) {
                var newReport = {};

                newReport.reportDates = {
                    startDate: firstAvailableDate.startDate + i,
                    endDate: firstAvailableDate.endDate + i
                };

                newReport.comparison = "State";
                newReport.timespan = newReport.reportDates.startDate + '/' + newReport.reportDates.endDate;

                missingDatesArray.push(
                    ReportsService.saveReport(newReport, schoolId)
                );
            }

            $q.all(missingDatesArray)
                .then(function(reports) {
                    var attachReportsToSchool = [];
                    for (var i = 0; i < reports.length; i++) {
                        attachReportsToSchool.push(
                          SchoolService.addReportToSchool(schoolId, reports[i])
                        )
                    }

                    return $q.all(attachReportsToSchool);
                })
                .then(function(reports) {
                    $state.go('dashboard', {'schoolId':reports[reports.length -1].schoolId , 'reportId':reports[reports.length -1].$id });
                    if(vm.firebaseReports) {
                        vm.firebaseReports =  vm.firebaseReports.concat(reports);
                    } else {
                        vm.firebaseReports = reports;
                    }
                    vm.report = reports[reports.length -1];
                })
                .catch(function(err) {
                    console.log(err);
                });
        }

        function createFirstReport(schoolId, foundCurrentDate) {
            var newReport = {};

            SchoolService.getSchoolCreationDate(schoolId)
                .then(function(date) {
                    fillMissingDates(schoolId, foundCurrentDate, determineSchoolAcademicYearToCreate( new Date(date) ), false);
                });
        }

        function startNewSchoolYear(schoolId, foundCurrentDate, lastReportDate) {
            fillMissingDates(schoolId, foundCurrentDate, lastReportDate, true)
            .then(function () {
                $rootScope.$broadcast('NewYearCreated');
            });
        }

        function viewReport(index) {
            vm.report = vm.firebaseReports[index];
            $state.go('dashboard', {'schoolId':vm.report.schoolId , 'reportId' :vm.report.$id });
        }

        function sendPasswordResetEmail(e) {
            e.preventDefault();
            var user = AuthService.getCurrentUser();
            AuthService.sendPasswordResetEmail(user.email)
                .then(function() {
                    $state.go('noauthsplash');
                });
        }

        function logout(e) {
            e.preventDefault();
            SchoolService.killSockets();
            ReportsService.killSockets();
            AuthService.killSockets();
            AuthService.logout().then(function() {
                $state.go('login');
            });
        }

    }
})();
