(function() {
    'use strict';

    angular
        .module('cscoe.settings.modal',[])
        .controller('SettingsModalCtrl', SettingsModalCtrl);

    /* @ngInject */
    function SettingsModalCtrl(report, ReportsService, ModalService) {
        var vm = this;
        vm.title = 'SettingsModalCtrl';

        activate();

        function activate() {
            vm.report = report;
            vm.updateReport = updateReport;
            vm.closeModal = closeModal;
        }

        function updateReport(comparison) {
            if (!comparison) {
                vm.report.comparison = ' ';
            } else{
                vm.report.comparison = comparison;
            }
            ReportsService.updateReport(vm.report)
                .then(function() {
                    closeModal();
                });
        }

        function closeModal() {
            ModalService.close();
        }
    }
})();
